import { useState, useEffect, useRef } from "react";

export default function useDelayedRender(enterDelay: number) {
  const isMounted = useRef(true);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    window.setTimeout(() => {
      if (isMounted.current) {
        setRendered(true);
      }
    }, enterDelay);

    return () => {
      isMounted.current = false;
    };
  }, [enterDelay]);

  return {
    rendered,
  };
}
