import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const metaHelmetContext = React.createContext("");

export function MetaTitleProvider(props: { base: string; children: React.ReactNode }) {
  const { base, children } = props;
  return (
    <HelmetProvider>
      <metaHelmetContext.Provider value={base}>{children}</metaHelmetContext.Provider>
    </HelmetProvider>
  );
}

interface IMetaTitleProps {
  path: string[];
}

export function MetaTitle(props: IMetaTitleProps) {
  const { path } = props;
  const base = React.useContext(metaHelmetContext);

  return (
    <Helmet>
      <title>{[...path, base].join(" | ")}</title>
    </Helmet>
  );
}
