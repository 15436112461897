import { ButtonGroup, Text } from "@chakra-ui/react";
import { EndpointIn, EndpointOut } from "svix";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Form from "@svix/common/widgets/Form";
import TextAreaField from "@svix/common/widgets/form/TextArea";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";

interface IDescriptionProps {
  endpoint: EndpointOut;
}

export default function Description(props: IDescriptionProps) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const queryKey = ["endpoints", endpoint.id];

  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<EndpointOut>(queryKey, endpoint, "description", async (form) => {
      const api = getSvix();
      return api.endpoint.update(user.app.id, endpoint.id, form as EndpointIn);
    });

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Card
        maxH={460}
        overflowY="scroll"
        title="Description"
        cta={
          isEditing ? (
            <ButtonGroup>
              <Button colorScheme="gray" size="xs" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" size="xs" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : (
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <Button
                type="button"
                size="xs"
                colorScheme="gray"
                onClick={edit}
                isDisabled={isReadOnly}
              >
                Edit
              </Button>
            </ReadOnlyTooltip>
          )
        }
      >
        {isEditing ? (
          <TextAreaField autoFocus name="description" control={formCtx.control} />
        ) : (
          <>
            {endpoint.description ? (
              <Text whiteSpace="pre-wrap">{endpoint.description}</Text>
            ) : (
              <Text variant="caption">No description</Text>
            )}
          </>
        )}
      </Card>
    </Form>
  );
}
