import * as React from "react";
import {
  FormControl,
  Text,
  Heading,
  Stack,
  useRadioGroup,
  FormLabel,
  StackDirection,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

import RadioField from "./RadioField";

type StringOrNumber = string | number;

export interface IRadioButton<T extends StringOrNumber> {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  value: T;
}

interface IRadioButtonsProps<T extends StringOrNumber> {
  buttons: readonly IRadioButton<T>[];
  control: any;
  direction?: StackDirection;
  isDisabled?: boolean;
  label?: string | React.ReactNode;
  name: string;
}

export default function RadioButtons<T extends StringOrNumber>(
  props: IRadioButtonsProps<T>
) {
  const { buttons, isDisabled, control, name, label, direction } = props;

  const {
    field: { ref, ...controlProps },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    defaultValue: "Test",
    rules: { required: true },
  });
  const { getRootProps, getRadioProps } = useRadioGroup(controlProps);

  const group = getRootProps();

  return (
    <FormControl isInvalid={invalid} id={name}>
      <FormLabel>{label}</FormLabel>
      <Stack {...group} direction={direction}>
        {buttons.map((button) => (
          <RadioField
            isDisabled={isDisabled}
            {...getRadioProps({ value: button.value })}
            key={button.value}
          >
            <Heading as="div" size="sm">
              {button.title}
            </Heading>
            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {button.description && (
              <Text mt={1} variant="caption">
                {button.description}
              </Text>
            )}
          </RadioField>
        ))}
      </Stack>
    </FormControl>
  );
}
