import { ButtonGroup, Flex, IconButton, Text } from "@chakra-ui/react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { FastApiResponse, FastApiWithPrevResponse } from "hooks/pagination";

interface IPaginationProps {
  response: FastApiResponse | undefined;
  pageNumber: number;
  nextPage: () => void;
  previousPage: () => void;
  isLoadingPrev: boolean;
  isLoadingNext: boolean;
}

export default function Pagination(props: IPaginationProps) {
  const { response, pageNumber, previousPage, nextPage } = props;

  const pageLength = Array.isArray(response?.data) ? response?.data.length : 0;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text as="div" mr={4} variant="caption">
        Showing {pageLength} {pageLength !== 1 ? "items" : "item"}
      </Text>
      <ButtonGroup spacing={2}>
        <IconButton
          aria-label="previous page"
          disabled={pageNumber === 0}
          variant="toolbar"
          size="sm"
          isLoading={props.isLoadingPrev}
          onClick={previousPage}
        >
          <ChevronLeft fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="next page"
          disabled={response?.done ?? true}
          variant="toolbar"
          isLoading={props.isLoadingNext}
          size="sm"
          onClick={nextPage}
        >
          <ChevronRight fontSize="small" />
        </IconButton>
      </ButtonGroup>
    </Flex>
  );
}

interface IPaginationWithPrevIterProps {
  response: FastApiWithPrevResponse | undefined;
  pageNumber: number;
  nextPage: () => void;
  previousPage: () => void;
  isLoadingPrev: boolean;
  isLoadingNext: boolean;
  isPagingForward?: boolean;
  isFirstPage?: boolean;
}

export function PaginationWithPrevIter(props: IPaginationWithPrevIterProps) {
  const { response, previousPage, nextPage, isPagingForward, isFirstPage } = props;

  const pageLength = Array.isArray(response?.data) ? response?.data.length : 0;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text as="div" mr={4} variant="caption">
        Showing {pageLength} {pageLength !== 1 ? "items" : "item"}
      </Text>
      <ButtonGroup spacing={2}>
        <IconButton
          aria-label="previous page"
          disabled={
            (isFirstPage || (isPagingForward === false && response?.done)) ?? true
          }
          variant="toolbar"
          size="sm"
          isLoading={props.isLoadingPrev}
          onClick={previousPage}
        >
          <ChevronLeft fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="next page"
          disabled={(isPagingForward === true && response?.done) ?? true}
          variant="toolbar"
          isLoading={props.isLoadingNext}
          size="sm"
          onClick={nextPage}
        >
          <ChevronRight fontSize="small" />
        </IconButton>
      </ButtonGroup>
    </Flex>
  );
}
