import { StrictMode } from "react";
import * as Sentry from "@sentry/react";
// eslint-disable-next-line import/default
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";

import * as C from "@svix/common/constants";
import { sentryErrorFilter } from "@svix/common/logger";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";

Sentry.init({
  enabled: import.meta.env.NODE_ENV !== "development",
  dsn: C.sentry.appPortalDsn,
  environment: C.envConfig.name,
  integrations: [Sentry.browserTracingIntegration(), Sentry.httpClientIntegration()],
  beforeSend: sentryErrorFilter,
  // Performance Monitoring
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
