import { Svix, SvixOptions } from "svix";

import { store } from "src/store";
import { SinksApi as SinksApiInternal } from "./sinks";

const svixCache = new Map<string, Svix>();

function getToken(): string {
  const appId = store.getState().applications.appId;
  return store.getState().applications.apps[appId!].auth.user!.token;
}

function getServerUrl(): string {
  const appId = store.getState().applications.appId;
  return store.getState().applications.apps[appId!].serverUrl;
}

interface IApiConfig {
  token: string;
  serverUrl: string;
}

export function getSvix(config?: IApiConfig) {
  const { token = getToken(), serverUrl = getServerUrl() } = config || {};

  if (svixCache.has(token)) {
    return svixCache.get(token)!;
  }

  const options: SvixOptions = {
    serverUrl,
  };

  const ret = new Svix(token, options);
  svixCache.set(token, ret);
  return ret;
}

export function SinksApi(config?: IApiConfig) {
  const { token = getToken(), serverUrl = getServerUrl() } = config || {};
  return SinksApiInternal(token, serverUrl);
}

export type {
  SinkIn,
  RabbitMQSink,
  SQSSink,
  SinkOut,
  SinkType,
  SinkTypeName,
} from "./sinks";
