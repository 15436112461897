import { Box, Button, useToast } from "@chakra-ui/react";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import baseTheme from "prism-react-renderer/themes/vsDark";

const theme = {
  ...baseTheme,
  plain: {
    color: "#9CDCFE",
    backgroundColor: "transparent",
  },
};

export interface ICodeProps {
  copyToClipboard?: boolean;
  language?: Language;
  code: string;
  title?: string;
  maxH?: string;
}

export default function Code({
  copyToClipboard,
  code,
  language = "javascript",
  title,
  maxH,
}: ICodeProps) {
  const toast = useToast();

  return (
    <Box bgColor="#282e4a" overflowX="hidden" borderRadius="lg" alignSelf="flex-start">
      {title && (
        <Box
          borderBottom="1px solid"
          borderColor="gray.600"
          color="gray.300"
          fontSize="sm"
          fontWeight="medium"
          px={4}
          py={2}
        >
          {title}
        </Box>
      )}
      <Box role="group" position="relative">
        <Box overflow="auto" maxH={maxH}>
          <Highlight {...defaultProps} code={code} language={language} theme={theme}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <Box as="pre" className={className} style={style} p={4} fontSize="sm">
                {tokens.map((line, i) => (
                  <div {...getLineProps({ line })} key={i}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token })} key={key} />
                    ))}
                  </div>
                ))}
              </Box>
            )}
          </Highlight>
        </Box>
        {copyToClipboard && (
          <Button
            _groupHover={{
              opacity: 1,
            }}
            opacity={0}
            position="absolute"
            right={2}
            size="xs"
            top={2}
            transition="opacity 0.15s ease-in"
            variant="solid"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(code);
                toast({
                  title: "Copied to clipboard",
                  status: "info",
                  duration: 2000,
                  isClosable: true,
                });
              } catch (err) {
                toast({
                  title: "Failed to copy",
                  description:
                    "This is likely due to a misconfiguration. Please contact us if the issue persists.",
                  status: "warning",
                  duration: 2000,
                  isClosable: true,
                });
              }
            }}
          >
            Copy
          </Button>
        )}
      </Box>
    </Box>
  );
}
