/* eslint-disable no-console */
import * as Sentry from "@sentry/react";

export type NotifiableError =
  | Error
  | { errorClass: string; errorMessage: string }
  | { name: string; message: string }
  | string;

export function logError(err: NotifiableError, context?: string) {
  Sentry.captureException(err, {
    extra: {
      context,
    },
  });

  if (import.meta.env.DEV) {
    console.error(err);
  }
}

export function logWarning(err: NotifiableError, context?: string) {
  Sentry.captureMessage(err.toString(), {
    extra: {
      context,
    },
    level: "warning",
  });

  if (import.meta.env.DEV) {
    console.warn(err);
  }
}

export function logInfo(err: NotifiableError, context?: string) {
  Sentry.captureMessage(err.toString(), {
    extra: {
      context,
    },
    level: "info",
  });

  if (import.meta.env.DEV) {
    console.info(err);
  }
}

export function sentryErrorFilter(event: Sentry.ErrorEvent, _hint: Sentry.EventHint) {
  // Add filtering for Sentry, if needed
  return event;
}
