import { Spinner, SpinnerProps } from "@chakra-ui/react";

import useDelayedRender from "../hooks/delayedRender";

export default function LoadingIndicator(props: SpinnerProps) {
  const { rendered } = useDelayedRender(300);
  return rendered ? (
    <Spinner size="xl" thickness="6px" color="blue.600" speed="0.65s" {...props} />
  ) : null;
}
