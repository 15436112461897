import { store } from "./store";

export interface RouteKeysType {
  [Identifier: string]: any;
}

export class RouteResolver {
  public routes: Record<string, any>;

  constructor(routes: Record<string, any>) {
    this.routes = routes;
  }

  public getRoute(name: string, _keys?: RouteKeysType): string {
    let dict = this.routes;
    if (!dict[name.split(".")[0]]) {
      name = `app.${name}`;
    }

    let path: string[] = [];
    name.split(".").forEach((key) => {
      const val =
        typeof dict[key] === "string"
          ? dict[key]
          : dict[key]._base
          ? dict[key]._base
          : key;
      path.push(val);

      dict = dict[key];
    });

    const appId = store.getState().applications.appId;
    if (appId && path[0] === ":appId") {
      path[0] = appId;
    }

    if (_keys) {
      const keys = Object.assign({}, _keys);

      path = path.map((pathComponent) => {
        return pathComponent
          .split("/")
          .map((val) => {
            if (val[0] === ":") {
              const ret = keys[val.slice(1)];
              if (ret === undefined) {
                throw new Error("Missing key: " + val.slice(1));
              }

              delete keys[val.slice(1)];
              return ret;
            }

            return val;
          })
          .join("/");
      });

      if (Object.keys(keys).length !== 0) {
        throw new Error("Too many keys for route.");
      }
    }

    return "/" + path.join("/");
  }
}
