import { Stack, Text, Link, ListItem, OrderedList } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { EndpointIn } from "svix";

import TextField from "@svix/common/widgets/form/TextField";

const teamsDocsURL =
  "https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=dotnet#create-incoming-webhooks-1";

export default function TeamsIntegration(props: {
  formCtx: UseFormReturn<EndpointIn, any>;
}) {
  const { formCtx } = props;

  return (
    <Stack spacing={4}>
      <Text fontWeight="semibold">To connect to Teams, follow these steps:</Text>
      <OrderedList spacing={1} stylePosition="inside">
        <ListItem>
          Open the channel in which you want to add the webhook and select ••• from the
          upper-right corner.
        </ListItem>
        <ListItem>Select Connectors from the dropdown menu.</ListItem>
        <ListItem>Search for Incoming Webhook and select Add.</ListItem>
        <ListItem>
          Select Configure, provide a name, and upload an image for your webhook if
          necessary.
        </ListItem>
        <ListItem>
          Copy the unique webhook URL present in the dialog. Paste the URL below.
        </ListItem>
      </OrderedList>
      <Link isExternal href={teamsDocsURL} color="interactive.accent">
        Learn more about Teams Incoming Webhooks
      </Link>
      <TextField
        autoFocus
        control={formCtx.control}
        name="url"
        label="Teams Incoming Webhook URL"
        type="url"
        isRequired
        placeholder="e.g. https://example.webhook.office.com/1234"
      />
    </Stack>
  );
}
