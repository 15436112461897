import { useBreakpointValue } from "@chakra-ui/react";

import TableCell, { ITableCellProps } from "./TableCell";
import { formatDateTime } from "../utils";

interface ITimestampTableCellProps extends ITableCellProps {
  ts?: Date;
}

export default function TimestampTableCell(props: ITimestampTableCellProps) {
  const { ts, ...rest } = props;
  const variant = useBreakpointValue({ base: "condensed", lg: "wide" });

  return (
    <TableCell
      tooltip={ts ? formatDateTime(ts, { withMs: true, utc: true }) : undefined}
      {...rest}
    >
      {ts &&
        formatDateTime(ts, {
          condensed: variant === "condensed",
        })}
    </TableCell>
  );
}
