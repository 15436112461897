import { Box, Flex, Skeleton, Text, Tooltip } from "@chakra-ui/react";
import { EndpointStats } from "svix";

type StatType = keyof EndpointStats;
interface IEndpointStat {
  name: StatType;
  value: number;
}

const order: StatType[] = ["success", "sending", "pending", "fail"];

function getStatColor(stat: StatType): string {
  switch (stat) {
    case "success":
      return "green.300";
    case "sending":
      return "cyan.400";
    case "pending":
      return "orange.300";
    case "fail":
      return "red.400";
    default:
      return "white";
  }
}

const defaultStats: IEndpointStat[] = [
  { name: "success", value: 0 },
  { name: "sending", value: 0 },
  { name: "pending", value: 0 },
  { name: "fail", value: 0 },
];

type IEndpointStatsProps = {
  stats?: IEndpointStat[];
  isLoading: boolean;
};

export function getFormattedStats(stats?: EndpointStats): IEndpointStat[] {
  return order.map((status: StatType) => ({
    name: status,
    value: stats?.[status] ?? 0,
  }));
}

function getTooltipText(value: number, total: number): string {
  if (total === 0) {
    return `${value.toLocaleString()} messages`;
  }
  return `${value.toLocaleString()} messages (${((value / total) * 100).toFixed(2)}%)`;
}

export default function EndpointStatChart(props: IEndpointStatsProps) {
  const { isLoading } = props;
  const stats = props.stats ?? defaultStats;
  const nonzeroStats = stats.filter((stat) => stat.value > 0);
  const total = stats.reduce((acc, stat) => acc + stat.value, 0);

  if (!isLoading && nonzeroStats.length === 0) {
    return (
      <Box>
        <Flex
          border="2px dotted"
          borderColor="background.modifier.border"
          borderLeftRadius="full"
          borderRightRadius="full"
          w="100%"
          h="20px"
        />
        <Text
          variant="caption"
          fontSize="xs"
          fontWeight="medium"
          letterSpacing={0.3}
          textTransform="uppercase"
          textAlign="center"
          mt={2}
          w="100%"
        >
          No messages received in the last 28 days
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Skeleton isLoaded={!isLoading} height="20px">
        <Flex borderLeftRadius="full" borderRightRadius="full" overflow="hidden">
          {nonzeroStats.map(({ name, value }, idx) => {
            const xScale = total && (value / total) * 100;
            return (
              <Tooltip
                hasArrow
                label={getTooltipText(value, total)}
                placement="top"
                key={name}
              >
                <Box
                  borderRightRadius={idx === nonzeroStats.length - 1 ? "md" : 0}
                  borderLeftRadius={idx === 0 ? "md" : 0}
                  bg={getStatColor(name)}
                  h="20px"
                  w={`${xScale}%`}
                />
              </Tooltip>
            );
          })}
        </Flex>
      </Skeleton>
      <Flex>
        {nonzeroStats.map(({ name, value }) => (
          <Flex key={name} alignItems="center" p={2} mr={2} mt={2}>
            <Box mr={2} bg={getStatColor(name)} w="8px" h="8px" />
            <Text
              color="primary"
              as="span"
              textTransform="uppercase"
              letterSpacing={0.3}
              fontSize="xs"
              fontWeight="medium"
            >
              {name} – {value.toLocaleString()}
            </Text>
          </Flex>
        ))}
      </Flex>
    </>
  );
}
