import { useEffect, useCallback, useState } from "react";
import { useToast } from "@chakra-ui/toast";
import pick from "lodash/pick";
import { FieldValues, useForm } from "react-hook-form";
import { MutationFunction, useMutation, useQueryClient } from "react-query";
import { ApiException } from "svix";

import { setErrors } from "../formUtils";

export default function useUpdateMutation<T extends FieldValues>(
  queryKey: string[],
  obj: T,
  field: keyof T,
  mutationFn: MutationFunction<T, Partial<T>>
) {
  const value = pick(obj, field) as any;
  const formCtx = useForm<T>(value);
  const [isEditing, setEditing] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [savedValue, setSavedValue] = useState({} as any);
  const queryClient = useQueryClient();
  const toast = useToast();
  const { setError, reset } = formCtx;

  useEffect(() => {
    reset(pick(obj, field) as any);
  }, [obj, field, reset]);

  const mutation = useMutation(mutationFn, {
    onError: (e: any) => {
      if (e instanceof ApiException && e.code === 404) {
        toast({
          title: "Failed to update",
          description: "This resource could not be found, maybe it was deleted?",
          status: "error",
        });
      }
      setErrors(setError, e.body);
    },
    onMutate: (form: Partial<T>) => {
      setSubmitting(true);
      queryClient.setQueryData(queryKey, { ...obj, ...form });
    },
    onSuccess: () => {
      setEditing(false);
      queryClient.invalidateQueries(queryKey);
    },
    onSettled: () => {
      setSubmitting(false);
    },
  });

  const cancelEdit = useCallback(() => {
    setEditing(false);
    reset(savedValue);
    queryClient.setQueryData(queryKey, { ...obj, ...savedValue });
  }, [obj, reset, savedValue, queryClient, queryKey]);

  const edit = useCallback(() => {
    setEditing(true);
    setSavedValue(value);
  }, [value]);

  return {
    edit,
    cancelEdit,
    formCtx,
    isSubmitting,
    mutate: (data: T) => {
      mutation.mutate({ ...obj, ...data });
    },
    isEditing,
  };
}
