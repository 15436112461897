import * as React from "react";
import { Spinner, Tag, TagLabel, TagLeftIcon, Tooltip } from "@chakra-ui/react";
import { Check, Close, Timer } from "@material-ui/icons";
import { MessageStatus } from "svix";

export interface IMessageStatusDisplayProps {
  status: MessageStatus;
  tooltip?: React.ReactNode;
  onChange?: () => void;
}

export default function MessageStatusDisplay(props: IMessageStatusDisplayProps) {
  let icon;
  let label;
  let theme;

  switch (props.status) {
    case MessageStatus.Success: {
      label = "Succeeded";
      icon = (
        <TagLeftIcon>
          <Check />
        </TagLeftIcon>
      );
      theme = "blue";
      break;
    }
    case MessageStatus.Pending: {
      label = "Failed";
      icon = (
        <TagLeftIcon>
          <Timer />
        </TagLeftIcon>
      );
      theme = "gray";
      break;
    }
    case MessageStatus.Sending: {
      label = "Attempting";
      icon = <Spinner size="xs" speed="0.8s" mr={2} />;
      theme = "gray";
      break;
    }
    case MessageStatus.Fail: {
      label = "Failed";
      icon = (
        <TagLeftIcon>
          <Close />
        </TagLeftIcon>
      );
      theme = "red";
      break;
    }
    default: {
      label = "UNK";
      icon = (
        <TagLeftIcon>
          <Close />
        </TagLeftIcon>
      );
      theme = "red";
      break;
    }
  }

  return (
    <Tooltip label={props.tooltip} hasArrow placement="top">
      <Tag borderRadius="full" size="sm" colorScheme={theme} ml={2}>
        {icon}
        <TagLabel>{label}</TagLabel>
      </Tag>
    </Tooltip>
  );
}
