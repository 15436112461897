import { Box, useRadio, RadioProps } from "@chakra-ui/react";

export default function RadioField({
  children,
  name,
  isDisabled,
  ...inputProps
}: RadioProps) {
  const { state, getInputProps, getCheckboxProps } = useRadio(inputProps);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input
        {...input}
        onChange={isDisabled ? undefined : input.onChange}
        readOnly={isDisabled}
      />
      <Box
        {...checkbox}
        cursor={isDisabled ? undefined : "pointer"}
        borderWidth="2px"
        bg={isDisabled && !state.isChecked ? "background.hover" : undefined}
        borderRadius="md"
        _checked={{
          borderColor: "blue.500",
        }}
        _focus={{
          boxShadow: isDisabled ? "none" : "outline",
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
}
