import { Text } from "@chakra-ui/react";
import { Step } from "react-joyride";

import ScreenTour from "./ScreenTour";

// FIXME: These steps are not final. This is not user-facing yet.
export default function EndpointsTour() {
  const userTourSteps: Step[] = [
    {
      target: "#endpoints-list",
      content: (
        <>
          <Text>
            This is the list of active endpoints you are currently listening to.
          </Text>
        </>
      ),
      disableBeacon: true,
      placement: "auto",
    },
    {
      target: "#add-endpoint-button",
      content: (
        <>
          <Text>To start receiving webhooks, you need to add an endpoint.</Text>
        </>
      ),
      placement: "left",
    },
    {
      target: "#logs-nav-item",
      content: (
        <>
          The logs page shows you all the messages that have been sent to your endpoints.
        </>
      ),
      placement: "right",
    },
  ];

  const dashboardTourSteps: Step[] = [
    {
      target: "body",
      content: <>This is the app portal.</>,
      placement: "center",
      disableBeacon: true,
    },
    {
      target: "#endpoints-list",
      content: <>This is an endpoint we've automatically created for you.</>,
      placement: "auto",
    },
  ];

  return (
    <>
      <ScreenTour tourName="endpoints" steps={userTourSteps} />
      <ScreenTour tourName="endpoints" isDashboardTour steps={dashboardTourSteps} />
    </>
  );
}
