import { useState, useCallback } from "react";
import { ButtonProps, Collapse, HStack, Stack, Text, Tooltip } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { EndpointIn } from "svix";

import Button from "@svix/common/widgets/Button";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";

import { useSlackOauth } from "src/hooks/integrations/slack";

export type SlackIntegrationProps = {
  formCtx: UseFormReturn<EndpointIn, any>;
};

export default function SlackIntegration(props: SlackIntegrationProps) {
  const { formCtx } = props;
  const [showEndpointURL, setShowEndpointURL] = useState(false);
  const [connectedToSlack, setConnectedToSlack] = useState(false);

  const onIncomingWebhookReceived = useCallback(
    (url: string) => {
      formCtx.setValue("url", url);
      setShowEndpointURL(true);
      setConnectedToSlack(true);
    },
    [formCtx, setShowEndpointURL, setConnectedToSlack]
  );

  const { onConnect, error: oauthError } = useSlackOauth(onIncomingWebhookReceived);

  return (
    <Stack>
      <Text fontWeight="semibold">Endpoint URL</Text>
      <HStack>
        <SlackButton onClick={onConnect} isDisabled={connectedToSlack}>
          {connectedToSlack ? "Connected" : "Connect to Slack"}
        </SlackButton>
        <Text>
          {"or "}
          <StyledLink
            onClick={() => {
              setShowEndpointURL(true);
              setConnectedToSlack(false);
            }}
          >
            <Tooltip
              label="Use an Incoming Webhooks URL from you own Slack app, if you have created one"
              fontSize="md"
            >
              Add your own Slack URL
            </Tooltip>
          </StyledLink>
        </Text>
      </HStack>
      <Collapse in={showEndpointURL}>
        <TextField
          control={formCtx.control}
          name="url"
          helperText="The URL where the webhook events will be sent to."
          type="url"
          isRequired
          isDisabled={connectedToSlack}
          placeholder="e.g. https://www.example.com/webhook"
        />
      </Collapse>
      {oauthError && <Text color="red.500">{oauthError}</Text>}
    </Stack>
  );
}

const SlackButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button {...rest} variant="outline">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{ height: "20px", width: "20px", marginRight: "12px" }}
        viewBox="0 0 122.8 122.8"
      >
        <path
          d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
          fill="#e01e5a"
        ></path>
        <path
          d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
          fill="#36c5f0"
        ></path>
        <path
          d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
          fill="#2eb67d"
        ></path>
        <path
          d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
          fill="#ecb22e"
        ></path>
      </svg>
      {children}
    </Button>
  );
};
