import {
  ListItem,
  ListItemProps,
  useColorModeValue,
  Box,
  ListIcon,
  Drawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  DrawerContent,
  IconProps,
  useBreakpointValue,
  useStyleConfig,
} from "@chakra-ui/react";
import { NavLink, matchPath, useLocation } from "react-router-dom";

const smVariant = { navigation: "drawer" };
const mdVariant = { navigation: "sidebar" };

export function SidebarIcon(props: IconProps) {
  return <ListIcon box="5" {...props} />;
}

interface ISidebarItemMatch {
  exact: boolean;
  path: string;
}

interface ISidebarItemProps {
  name: string;
  children: React.ReactNode;
  to: string;
  matchRoutes?: ISidebarItemMatch[];
  matchColor?: string;
  hasBorder?: boolean;
  id?: string;
  onClick?: () => void;
}

export function SidebarItem(props: ISidebarItemProps) {
  const { children, name, to, matchColor, onClick, id, matchRoutes } = props;
  const location = useLocation();
  const toMatch = matchRoutes ?? [{ path: to, exact: false }];
  const match = toMatch.find((m) => matchPath(location.pathname, m));
  const hoverColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <ListItemLink
      id={id}
      onClick={onClick}
      name={name}
      to={to}
      fontSize="md"
      borderRadius={4}
      color={match ? matchColor ?? "brand.500" : undefined}
      fontWeight={500}
      transition="background-color 0.15s ease-in-out, color 0.15s ease-in-out"
      p={3}
      _hover={{ bgColor: hoverColor }}
      {...(props.hasBorder && {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor,
      })}
    >
      {children}
    </ListItemLink>
  );
}

function ListItemLink(props: ListItemProps & { name: string; to: string }) {
  const { children, name, to, ...rest } = props;

  if (to.startsWith("http")) {
    return (
      <a href={to} target="_blank" rel="noreferrer" data-cy={`nav-${name}`}>
        <ListItem {...rest}>{children}</ListItem>
      </a>
    );
  }

  return (
    <NavLink
      to={to}
      data-cy={`nav-${name}`}
      activeStyle={{
        color: "blue.500",
      }}
    >
      <ListItem {...rest}>{children}</ListItem>
    </NavLink>
  );
}

interface ISidebarProps {
  children: React.ReactNode;
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

const Sidebar = (props: ISidebarProps) => {
  const variant = useBreakpointValue({ base: smVariant, md: mdVariant });
  const styles = useStyleConfig("Sidebar");

  return variant?.navigation === "sidebar" ? (
    <Box position="fixed" left={0} w="240px" top={0} h="100vh" sx={styles}>
      {props.children}
    </Box>
  ) : (
    <Drawer
      isOpen={props.mobileDrawerOpen}
      placement="left"
      onClose={() => props.setMobileDrawerOpen(false)}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>{props.children}</DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Sidebar;
