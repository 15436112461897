import { useCallback, useEffect, useState } from "react";
import {
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
  Tag,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "react-query";
import { TemplateOut } from "svix";
import { TransformationTemplateApi } from "svix/dist/openapi";

import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getSvix } from "src/api";
import { useAppQuery } from "src/hooks/api";
import { useInngestIntegration } from "src/hooks/integrations/inngest";
import { useSlackOauth } from "src/hooks/integrations/slack";
import { useAppSelector } from "src/hooks/store";
import ConfigurationModal from "./ConfigurationModal";
import { templateIdMetadataField } from "../EndpointCreate/TemplateForm";

export const SupportedIntegrations = ["Slack", "Inngest"];

export default function Integrations() {
  const { data: templateList } = useAppQuery(["transformationTemplates"], () => {
    const svix = getSvix();
    const api = new TransformationTemplateApi(svix._configuration);
    return api.v1TransformationTemplateList({ limit: 250 });
  });
  const isIntegrationsMode = useAppSelector(
    (state) => state.embedConfig.isIntegrationsMode
  );

  return (
    <Stack p={isIntegrationsMode ? 4 : 0}>
      <MetaTitle path={["Integrations"]} />
      <Heading as="h1" my={2} size="md">
        Integrations
      </Heading>
      {templateList?.data
        .filter((t) => SupportedIntegrations.includes(t.kind))
        .map((template) => (
          <IntegrationItem key={template.id} template={template} />
        ))}
    </Stack>
  );
}

interface IntegrationItemProps {
  template: TemplateOut;
}

function IntegrationItem({ template }: IntegrationItemProps) {
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.auth.user)!;
  const isIntegrationsButton = useAppSelector(
    (state) => state.embedConfig.integrationButtonType === template.kind
  );

  const {
    isOpen: isConfigModalOpen,
    onOpen: onConfigModalOpen,
    onClose: onConfigModalClose,
  } = useDisclosure();
  const toast = useToast();
  const [autoOpenFired, setAutoOpenFired] = useState(false);

  const {
    data: endpoints,
    isLoading,
    isSuccess: endpointsLoaded,
  } = useAppQuery("endpoints", async () => {
    const api = getSvix();
    return api.endpoint.list(user.app.id, { limit: 250 });
  });
  const integrationEndpoint = endpoints?.data.find(
    (e) => e.metadata[templateIdMetadataField] === template.id
  );

  const addEndpointMutation = useMutation(async (url: string) => {
    const api = getSvix();
    const endpoint = await api.endpoint.create(user.app.id, {
      url: url,
      description: `${template.name} integration`,
      disabled: false,
      filterTypes: template.filterTypes,
      metadata: {
        [templateIdMetadataField]: template.id,
      },
    });
    await api.endpoint.transformationPartialUpdate(user.app.id, endpoint.id, {
      enabled: true,
      code: template.transformation,
    });
    await queryClient.invalidateQueries("endpoints");

    onConfigModalOpen();
    toast({
      title: `Connected to ${template.name}`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  });

  const onWebhookUrlReceived = useCallback(
    (url: string) => {
      addEndpointMutation.mutate(url);
    },
    [addEndpointMutation]
  );

  const {
    onConnect: onSlackConnect,
    isLoading: isSlackLoading,
    error: slackError,
  } = useSlackOauth(onWebhookUrlReceived);
  const { onConnect: onInngestConnect, error: inngestError } =
    useInngestIntegration(onWebhookUrlReceived);

  // FIXME: We need a more generic way to handle this
  const onConnect = template.kind === "Slack" ? onSlackConnect : onInngestConnect;
  const isConnectionLoading = template.kind === "Slack" ? isSlackLoading : false;

  useEffect(() => {
    if (autoOpenFired) {
      // autoOpen only on the first time this loads
      return;
    }

    if (endpointsLoaded && isIntegrationsButton) {
      setAutoOpenFired(true);
      if (integrationEndpoint) {
        onConfigModalOpen();
      } else {
        onConnect();
      }
    }
  }, [
    autoOpenFired,
    endpointsLoaded,
    isIntegrationsButton,
    onConfigModalOpen,
    onConnect,
    integrationEndpoint,
  ]);

  useEffect(() => {
    if (slackError || inngestError) {
      toast({
        title: `Could not connect to ${template.name}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      onConfigModalClose();

      if (isIntegrationsButton) {
        window.close();
      }
    }
  }, [
    slackError,
    inngestError,
    template.name,
    toast,
    onConfigModalClose,
    isIntegrationsButton,
  ]);

  return (
    <>
      <Skeleton isLoaded={!isLoading}>
        <Card mt={2}>
          <HStack justifyContent="space-between">
            <HStack spacing={4}>
              <Image src={template.logo} w="36px" h="36px" />
              <Stack spacing={1}>
                <Text fontWeight="bold">
                  {template.name}
                  {integrationEndpoint && (
                    <Tag colorScheme="green" ml={2}>
                      Connected
                    </Tag>
                  )}
                </Text>
                <Text>{template.description}</Text>
              </Stack>
            </HStack>
            {integrationEndpoint ? (
              <Button onClick={onConfigModalOpen}>Configure</Button>
            ) : (
              <Button
                onClick={onConnect}
                isLoading={addEndpointMutation.isLoading || isConnectionLoading}
              >
                Connect
              </Button>
            )}
          </HStack>
        </Card>
        {integrationEndpoint && (
          <ConfigurationModal
            isOpen={isConfigModalOpen}
            onClose={onConfigModalClose}
            integrationEndpoint={integrationEndpoint}
            template={template}
          />
        )}
      </Skeleton>
    </>
  );
}
