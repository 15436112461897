import { useState } from "react";
import { useColorMode } from "@chakra-ui/color-mode";
import { Flex, Box } from "@chakra-ui/layout";

import AppBar from "./AppBar";
import Drawer, { drawerWidth } from "./Drawer";
import NavigationTabs from "../components/NavigationTabs";

interface IAppChrome {
  isEmbed: boolean;
  noGutters: boolean;
}

const MIN_APP_PORTAL_EMBED_HEIGHT = 650;

export default function AppChrome({
  children,
  isEmbed,
  noGutters,
}: React.PropsWithChildren<IAppChrome>) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const { colorMode } = useColorMode();

  return (
    <>
      {isEmbed ? (
        <NavigationTabs noGutters={noGutters} />
      ) : (
        <>
          <AppBar
            mobileDrawerOpen={mobileDrawerOpen}
            setMobileDrawerOpen={setMobileDrawerOpen}
          />
          <Drawer
            mobileDrawerOpen={mobileDrawerOpen}
            setMobileDrawerOpen={setMobileDrawerOpen}
          />
        </>
      )}

      <Flex
        as="main"
        flexDir="column"
        flexGrow={1}
        bg="background.primary"
        color="text.primary"
        className={`theme-${colorMode}`}
        ml={[0, 0, isEmbed ? 0 : drawerWidth]}
        px={noGutters ? 0 : 8}
        py={isEmbed ? 4 : 8}
        minHeight={isEmbed ? MIN_APP_PORTAL_EMBED_HEIGHT : "100%"}
      >
        {children}
        {isEmbed && <Box mt="1em" data-iframe-height />}
      </Flex>
    </>
  );
}
