import * as React from "react";
import { Box, Center, Flex, FlexProps, Tag, Tooltip } from "@chakra-ui/react";

interface INavBarTag {
  label: string;
  colorScheme: string;
  tooltip?: React.ReactNode | string;
}

interface INavbarProps extends FlexProps {
  tag?: INavBarTag;
}

export const NAVBAR_HEIGHT = "64px";

export default function Navbar(props: INavbarProps) {
  const { children, tag, ...rest } = props;
  return (
    <>
      <Box height={NAVBAR_HEIGHT} data-spacer />
      <Flex
        as="nav"
        align="center"
        wrap="wrap"
        padding="0 1rem"
        position="fixed"
        bg="brand.500"
        ml={[0, 0, 240]}
        width={["100%", "100%", "calc(100% - 240px)"]}
        height={NAVBAR_HEIGHT}
        zIndex={10}
        {...(tag && {
          borderBottom: "2px",
          borderColor: `${tag.colorScheme}.500`,
        })}
        {...rest}
      >
        {tag && (
          <Center top="54px" position="absolute" w="100%">
            <Tooltip label={tag.tooltip} hasArrow>
              <Tag size="sm" variant="solid" colorScheme={tag.colorScheme}>
                {tag.label}
              </Tag>
            </Tooltip>
          </Center>
        )}
        {children}
      </Flex>
    </>
  );
}
