import { useState } from "react";
import { useToast } from "@chakra-ui/react";

import useTimeout from "./useTimeout";

export default function useCopyToClipboard(
  value: string | undefined
): [boolean, () => Promise<void>] {
  const [copied, setCopied] = useState(false);
  const toast = useToast();
  const setTimeout = useTimeout();

  async function copyToClipboard() {
    if (value) {
      try {
        await navigator.clipboard.writeText(value);
      } catch (e) {
        toast({
          title: "Failed to copy",
          description:
            "This is likely due to a misconfiguration. Please contact us if the issue persists.",
          status: "warning",
          duration: 2000,
          isClosable: true,
        });
      }
      toast({ title: "Copied to clipboard", status: "info" });
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } else {
      toast({ title: "Failed to copy", status: "warning" });
    }
  }

  return [copied, copyToClipboard];
}
