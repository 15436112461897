import { useState } from "react";
import * as React from "react";
import {
  Checkbox,
  CheckboxGroup,
  Box,
  Flex,
  Input,
  FormLabel,
  Stack,
  FormControl,
  Text,
  useColorModeValue,
  Link,
  Tag,
  Tooltip,
} from "@chakra-ui/react";
import { Info } from "@material-ui/icons";
import { useController, ControllerRenderProps } from "react-hook-form";
import { EventTypeOut } from "svix";

import Markdown from "./Markdown";
import { DotNotationGroup, getGroupedEventTypes, wrapAround } from "../utils";

interface IMultiselectProps {
  label?: React.ReactNode | string;
  placeholder?: string;
  control: any;
  name: string;
  required?: boolean;
  emptyState?: React.ReactNode | string;
  filterInputRef?: React.RefObject<HTMLInputElement>;
}

function someNotAll<T>(collection: T[], condition: (t: T) => boolean): boolean {
  const allTrue = collection.every(condition);
  const someTrue = collection.some(condition);
  return someTrue && !allTrue;
}

function flatten(groups: DotNotationGroup[]): DotNotationGroup[] {
  if (Array.isArray(groups)) {
    return groups.reduce((acc: DotNotationGroup[], curr) => {
      acc.push(curr);
      return acc.concat(flatten(curr.items));
    }, []);
  } else {
    return groups;
  }
}

function getEventsFromGroup(group: DotNotationGroup): string[] {
  if (group.items.length === 0) {
    return [group.path];
  } else {
    return group.items.flatMap(getEventsFromGroup);
  }
}

interface IEventTypeGroupProps extends Omit<ControllerRenderProps, "ref" | "onChange"> {
  name: string;
  visibleEventTypes: EventTypeOut[];
  group: DotNotationGroup;
  value: string[];
  focusedItem?: DotNotationGroup;
  nestingLevel: number;
  isArchived: (eventTypeName: string) => boolean;
  selectItem: (item: DotNotationGroup, enabled: boolean) => void;
}

function EventTypeGroup(props: IEventTypeGroupProps) {
  const {
    name,
    group,
    visibleEventTypes,
    value,
    focusedItem,
    nestingLevel,
    isArchived,
    selectItem,
    ...controlledProps
  } = props;
  const hoverBg = useColorModeValue("brand.50", "whiteAlpha.100");
  const hoverBorder = useColorModeValue("brand.300", "brand.700");
  const borderColor = useColorModeValue("gray.300", "gray.800");

  const groupEventTypes = getEventsFromGroup(group);
  const et = visibleEventTypes.find((et) => et.name === group.path);
  const description = et?.description.trim();

  return (
    <Box>
      <Tooltip
        openDelay={250}
        isDisabled={!(et?.description.trim() || et?.deprecated)}
        label={
          <EventTypeTooltipLabel description={description} deprecated={et?.deprecated} />
        }
      >
        <Box fontWeight="semibold">
          <Checkbox
            id={`${name}-${group.path}`}
            key={group.path}
            isIndeterminate={someNotAll(groupEventTypes, (item) => value.includes(item))}
            isChecked={groupEventTypes.every((item) => value.includes(item))}
            opacity={isArchived(group.path) ? 0.7 : 1}
            bg={group.path === focusedItem?.path ? hoverBg : undefined}
            borderColor={group.path === focusedItem?.path ? hoverBorder : borderColor}
            _hover={{ bg: hoverBg, borderColor: hoverBorder }}
            display="flex"
            alignItems="center"
            borderRadius="md"
            ml={`${20 * nestingLevel}px`}
            colorScheme="brand"
            py={1}
            px={2}
            size="sm"
            onChange={(e) => selectItem(group, e.currentTarget.checked)}
          >
            {group.key}
            {description && (
              <Info
                style={{
                  marginBottom: "0.2rem",
                  marginLeft: "0.4rem",
                  fontSize: "1rem",
                  opacity: 0.3,
                }}
              />
            )}
            {isArchived(group.path) && (
              <Tag mt="0.4em" ml={2} size="sm">
                Archived
              </Tag>
            )}
            {et?.deprecated && (
              <Tag
                size="sm"
                variant="outline"
                colorScheme="yellow"
                ml={2}
                fontWeight="semibold"
              >
                Deprecated
              </Tag>
            )}
          </Checkbox>
        </Box>
      </Tooltip>
      {group.items.map((eventType) => {
        const etGroupItem = visibleEventTypes.find((et) => et.name === eventType.path);
        const itemDescription = etGroupItem?.description.trim();

        return (
          <React.Fragment key={eventType.path}>
            {eventType.items.length ? (
              <EventTypeGroup
                {...props}
                group={eventType}
                nestingLevel={nestingLevel + 1}
                isArchived={isArchived}
              />
            ) : (
              <Tooltip
                whiteSpace="pre-wrap"
                isDisabled={!(itemDescription || etGroupItem?.deprecated)}
                label={
                  <EventTypeTooltipLabel
                    description={itemDescription}
                    deprecated={etGroupItem?.deprecated}
                  />
                }
                shouldWrapChildren
                openDelay={250}
              >
                <Checkbox
                  id={`${name}-${eventType.path}`}
                  display="flex"
                  alignItems="center"
                  _hover={{ bg: hoverBg, borderColor: hoverBorder }}
                  bg={eventType.path === focusedItem?.path ? hoverBg : undefined}
                  borderColor={
                    eventType.path === focusedItem?.path ? hoverBorder : borderColor
                  }
                  borderRadius="md"
                  ml={`${20 * (nestingLevel + 1)}px`}
                  opacity={isArchived(eventType.path) ? 0.7 : 1}
                  p={1}
                  size="sm"
                  colorScheme="brand"
                  {...controlledProps}
                  value={eventType.path}
                >
                  <Text as="span">
                    {eventType.path.slice(0, -eventType.key.length)}
                    <Text fontWeight="semibold" as="span">
                      {eventType.key}
                    </Text>
                  </Text>
                  {itemDescription && (
                    <Info
                      style={{
                        marginBottom: "0.2rem",
                        marginLeft: "0.4rem",
                        fontSize: "1rem",
                        opacity: 0.3,
                      }}
                    />
                  )}
                  {isArchived(eventType.path) && (
                    <Tag mt="0.4em" ml={2} size="sm">
                      Archived
                    </Tag>
                  )}
                  {etGroupItem?.deprecated && (
                    <Tag
                      size="sm"
                      variant="outline"
                      colorScheme="yellow"
                      ml={2}
                      fontWeight="semibold"
                    >
                      Deprecated
                    </Tag>
                  )}
                </Checkbox>
              </Tooltip>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
}

function EventTypeTooltipLabel({
  description,
  deprecated,
}: {
  description?: string;
  deprecated?: boolean;
}) {
  return (
    <>
      {description && <Markdown>{description}</Markdown>}
      {deprecated && (
        <div>
          Deprecated: This event type may be removed in the future and should no longer be
          used.
        </div>
      )}
    </>
  );
}

export interface EventsListProps extends IMultiselectProps {
  availableEvents: EventTypeOut[];
}

export default function EventsList(props: EventsListProps) {
  const { required, label, control, name, availableEvents } = props;
  const [filterStr, setFilterStr] = useState("");
  const [focusedItem, setFocusedItem] = useState<DotNotationGroup>();
  const emptyStateColor = useColorModeValue("gray.900", "gray.200");

  const {
    field: { value: rawValue, onChange, ref, ...controlledProps },
  } = useController({
    name,
    control,
  });
  const value: string[] = rawValue ?? [];
  const initialValue = React.useMemo(() => value, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredEventTypes = availableEvents
    .filter((evt) => !evt.archived || initialValue.includes(evt.name))
    .filter((evt) => evt.name.toLowerCase().includes(filterStr.toLowerCase()));

  const { groupedEventTypes } = getGroupedEventTypes(filteredEventTypes);

  const getNextItem = (curItem?: DotNotationGroup): DotNotationGroup => {
    const flattenedItems = flatten(groupedEventTypes);
    if (!curItem) {
      return groupedEventTypes[0];
    }

    const idx = flattenedItems.findIndex((item) => item.path === curItem.path);
    const nextIdx = wrapAround(idx + 1, flattenedItems.length);
    return flattenedItems[nextIdx];
  };

  const getPrevItem = (curItem?: DotNotationGroup): DotNotationGroup => {
    const flattenedItems = flatten(groupedEventTypes);
    if (!curItem) {
      return flattenedItems[flattenedItems.length - 1];
    }

    const idx = flattenedItems.findIndex((item) => item.path === curItem.path);
    const prevIdx = wrapAround(idx - 1, flattenedItems.length);
    return flattenedItems[prevIdx];
  };

  const isArchived = (eventTypeName: string): boolean => {
    return availableEvents.find((et) => et.name === eventTypeName)?.archived ?? false;
  };

  const selectItem = (item: DotNotationGroup, enabled: boolean) => {
    const isGroup = item.items.length > 0;
    if (enabled) {
      if (isGroup) {
        onChange({
          target: {
            name,
            value: [...value, ...getEventsFromGroup(item)],
          },
        });
      } else {
        onChange({
          target: {
            name,
            value: [...value, item.path],
          },
        });
      }
    } else {
      if (isGroup) {
        onChange({
          target: {
            name,
            value: value.filter((i) => !getEventsFromGroup(item).includes(i)),
          },
        });
      } else {
        onChange({
          target: {
            name,
            value: value.filter((r: string) => r !== item.path),
          },
        });
      }
    }
  };

  React.useEffect(() => {
    // Scroll `focusedItem` into view
    if (focusedItem) {
      const elem = document.getElementById(`${name}-${focusedItem.path}`);
      elem?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [focusedItem, name]);

  React.useEffect(() => {
    // Select first item in list if `focusedItem` isn't present in the list
    if (filterStr) {
      const flattenedItems = flatten(groupedEventTypes);
      if (!flattenedItems.find((e) => e.path === focusedItem?.path)) {
        setFocusedItem(flattenedItems[0]);
      }
    }
  }, [filterStr, focusedItem, groupedEventTypes]);

  const bg = useColorModeValue("gray.100", "whiteAlpha.50");

  return (
    <FormControl id={name} isRequired={required}>
      <FormLabel>{label}</FormLabel>
      <Input
        ref={props.filterInputRef}
        size="sm"
        placeholder="Search events..."
        variant="filled"
        value={filterStr}
        onChange={(e) => setFilterStr(e.target.value)}
        onKeyDown={(evt) => {
          if (availableEvents.length === 0) {
            return;
          }

          if (evt.key === "ArrowDown") {
            setFocusedItem(getNextItem(focusedItem));
          }
          if (evt.key === "ArrowUp") {
            setFocusedItem(getPrevItem(focusedItem));
          }
          if (evt.key === "Enter") {
            evt.preventDefault();
            if (focusedItem) {
              const input = document.getElementById(
                `${name}-${focusedItem.path}`
              ) as HTMLInputElement;
              selectItem(focusedItem, !input.checked);
            }
          }
        }}
      />
      <Box p={2} mt={2} borderRadius="input" bg={bg} height="250px" overflowY="scroll">
        <CheckboxGroup onChange={onChange} value={value}>
          <Stack spacing={0}>
            {groupedEventTypes.map((group) => (
              <EventTypeGroup
                isArchived={isArchived}
                key={group.path}
                group={group}
                visibleEventTypes={filteredEventTypes}
                focusedItem={focusedItem}
                nestingLevel={0}
                value={value}
                selectItem={selectItem}
                {...controlledProps}
              />
            ))}
          </Stack>
          {availableEvents.length === 0 && (
            <Text
              alignItems="center"
              display="flex"
              justifyContent="center"
              variant="caption"
              w="100%"
              h="100%"
            >
              No events available.
            </Text>
          )}
        </CheckboxGroup>
      </Box>
      <Flex mt={1}>
        {value.length === 0 ? (
          <Box mt={1}>
            <Text as="span" color={emptyStateColor} fontWeight="medium">
              {props.emptyState}
            </Text>
            <Text as="span" variant="caption">
              {" "}
              Select from the above list to filter.
            </Text>
          </Box>
        ) : (
          <Text as="div" color={emptyStateColor} fontWeight="medium" mt={1}>
            {value.length} event{value.length !== 1 ? "s" : ""} selected |{" "}
            <Link color="brand.500" href="#" size="sm" onClick={() => onChange([])}>
              Clear
            </Link>
          </Text>
        )}
      </Flex>
    </FormControl>
  );
}
