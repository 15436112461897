import { useState } from "react";
import { Stack, Text, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { EndpointOut } from "svix";
import { EndpointApi } from "svix/dist/openapi";

import Card from "@svix/common/widgets/Card";
import StyledLink from "@svix/common/widgets/Link";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";

interface TransformationCardProps {
  endpoint: EndpointOut;
}
export default function TransformationCard(props: TransformationCardProps) {
  const user = useAppSelector((state) => state.auth.user)!;

  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const sv = getSvix();
  const config = sv._configuration;
  const endpApi = new EndpointApi(config);

  useAppQuery(["endpoints", props.endpoint.id, "transformation"], async () => {
    const trfx = await endpApi.v1EndpointTransformationGet({
      appId: user.app.id,
      endpointId: props.endpoint.id,
    });
    setEnabled(trfx.enabled || false);
    setLoading(false);
    return trfx;
  });

  const setTransformationState = async (enabled: boolean) => {
    setLoading(true);
    await endpApi.v1EndpointTransformationPartialUpdate({
      appId: user.app.id,
      endpointId: props.endpoint.id,
      endpointTransformationIn: { enabled },
    });
    setEnabled(enabled);
    setLoading(false);
  };

  return (
    <>
      <Card title="Transformations">
        <Stack direction="column" spacing={4}>
          <Text>
            Transformations allow you to modify a webhook's payload and redirect it to a
            different URL.{" "}
            <StyledLink
              color="interactive.accent"
              href="https://docs.svix.com/transformations"
            >
              Learn more
            </StyledLink>
          </Text>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              Enabled
            </FormLabel>
            <Switch
              isChecked={enabled}
              onChange={(e) => setTransformationState(e.target.checked)}
              disabled={loading}
            />
          </FormControl>
          <StyledLink
            fontWeight="semibold"
            color="interactive.accent"
            to={routeResolver.getRoute("endpoints._id.transformation", {
              endpId: props.endpoint.id,
            })}
          >
            Edit transformation
          </StyledLink>
        </Stack>
      </Card>
    </>
  );
}
