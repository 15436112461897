import { Box, ButtonGroup, useBoolean, Alert } from "@chakra-ui/react";
import { union } from "lodash";
import { EndpointIn, EndpointOut, EventTypeOut } from "svix";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form from "@svix/common/widgets/Form";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAllEventTypes } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";

interface IEndpointEventTypes {
  endpoint: EndpointOut;
}

const NUM_DEFAULT_VISIBLE_ITEMS = 3;

export default function EndpointEventTypes(props: IEndpointEventTypes) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const [showAllEvents, setShowAllEvents] = useBoolean();

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  const queryKey = ["endpoints", endpoint.id];

  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<EndpointOut>(queryKey, endpoint, "filterTypes", async (form) => {
      const api = getSvix();
      if (form.filterTypes && form.filterTypes.length === 0) {
        delete form["filterTypes"];
      }
      return api.endpoint.update(user.app.id, endpoint.id, form as EndpointIn);
    });

  const { data: allEvents } = useAllEventTypes();
  const availableEvents = union(
    allEvents?.data || [],
    // This might contain deleted or feature flagged event types not returned by useAllEventTypes
    (endpoint.filterTypes || []).map((ft) => ({ name: ft } as EventTypeOut))
  );

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Stat
        name="Subscribed events"
        cta={
          isEditing ? (
            <ButtonGroup ml={2} size="xs">
              <Button colorScheme="gray" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : (
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <Button
                isDisabled={isReadOnly}
                type="button"
                size="xs"
                colorScheme="gray"
                ml={2}
                onClick={edit}
                key="edit"
              >
                Edit
              </Button>
            </ReadOnlyTooltip>
          )
        }
      >
        {isEditing ? (
          <>
            {formCtx.formState.errors.filterTypes && (
              <Alert status="error">
                {
                  /* Have to cast because the type from this library is wrong */
                  (formCtx.formState.errors.filterTypes as any).message
                }
              </Alert>
            )}
            <EventsList
              availableEvents={availableEvents}
              name="filterTypes"
              control={formCtx.control}
            />
          </>
        ) : endpoint.filterTypes ? (
          <Box>
            {endpoint.filterTypes
              .slice(0, showAllEvents ? undefined : NUM_DEFAULT_VISIBLE_ITEMS)
              .map((eventType) => (
                <Box key={eventType}>{eventType}</Box>
              ))}
            {!showAllEvents && endpoint.filterTypes.length > NUM_DEFAULT_VISIBLE_ITEMS && (
              <Button
                size="xs"
                colorScheme="blue"
                variant="ghost"
                onClick={setShowAllEvents.on}
              >
                + {endpoint.filterTypes.length - NUM_DEFAULT_VISIBLE_ITEMS} More
              </Button>
            )}
          </Box>
        ) : (
          "Listening to all events"
        )}
      </Stat>
    </Form>
  );
}
