import { useState, useCallback } from "react";
import {
  Image,
  ButtonProps,
  Collapse,
  HStack,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { EndpointIn } from "svix";

import Button from "@svix/common/widgets/Button";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";

import { useInngestIntegration } from "src/hooks/integrations/inngest";
import inngestLogo from "src/img/integrations/inngest-icon.png";

export type InngestIntegrationProps = {
  formCtx: UseFormReturn<EndpointIn, any>;
};

export default function InngestIntegration(props: InngestIntegrationProps) {
  const { formCtx } = props;
  const [showEndpointURL, setShowEndpointURL] = useState(false);
  const [connectedToInngest, setConnectedToInngest] = useState(false);

  const onIncomingWebhookReceived = useCallback(
    (url: string) => {
      formCtx.setValue("url", url);
      setShowEndpointURL(true);
      setConnectedToInngest(true);
    },
    [formCtx, setShowEndpointURL, setConnectedToInngest]
  );

  const { onConnect, error: connectionError } = useInngestIntegration(
    onIncomingWebhookReceived
  );

  return (
    <Stack>
      <Text fontWeight="semibold">Endpoint URL</Text>
      <HStack>
        <InngestButton onClick={onConnect} isDisabled={connectedToInngest}>
          {connectedToInngest ? "Connected" : "Connect to Inngest"}
        </InngestButton>
        <Text>
          {"or "}
          <StyledLink
            onClick={() => {
              setShowEndpointURL(true);
              setConnectedToInngest(false);
            }}
          >
            <Tooltip
              label="Use an Inngest Webhook from Inngest Cloud, if you have created one"
              fontSize="md"
            >
              Add your own Inngest Webhook URL
            </Tooltip>
          </StyledLink>
        </Text>
      </HStack>
      <Collapse in={showEndpointURL}>
        <TextField
          control={formCtx.control}
          name="url"
          helperText="The URL where the webhook events will be sent to."
          type="url"
          isRequired
          isDisabled={connectedToInngest}
          placeholder="e.g. https://www.example.com/webhook"
        />
      </Collapse>
      {connectionError && <Text color="red.500">{connectionError}</Text>}
    </Stack>
  );
}

const InngestButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      leftIcon={<Image height="1.4em" src={inngestLogo} />}
      {...rest}
      variant="outline"
    >
      {children}
    </Button>
  );
};
