import { useRef, useEffect } from "react";

/**
 * A custom hook to bind setTimeout to a functional component.
 * It won't execute the timeout handler if the component is no
 * longer mounted.
 */
function useTimeout() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (handler: () => void, timeout: number) => {
    window.setTimeout(() => {
      if (isMounted.current) {
        handler();
      }
    }, timeout);
  };
}

export default useTimeout;
