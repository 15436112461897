import * as React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  InputProps,
} from "@chakra-ui/react";
import { FieldError, useController } from "react-hook-form";

function getErrorMessage(error: FieldError | undefined): string {
  if (!error) {
    return "";
  }
  if (error.message) {
    return error.message;
  }
  if (error.type === "required") {
    return "Required";
  }
  if (error.type === "maxLength") {
    return "Max length exceeded";
  }
  return "Invalid";
}

export interface ITextFieldProps extends InputProps {
  control: any;
  name: string;
  label?: React.ReactNode | string;
  helperText?: React.ReactNode | string;
  required?: boolean;
}

export default function TextField({
  control,
  helperText,
  label,
  name,
  required,
  ...inputProps
}: ITextFieldProps) {
  const {
    field: { ref, value, ...controlProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: "",
  });

  const errorMessage = getErrorMessage(error);

  return (
    <FormControl isInvalid={invalid} id={name} isRequired={required}>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {label && <FormLabel>{label}</FormLabel>}
      <Input variant="filled" {...inputProps} {...controlProps} value={value ?? ""} />
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
