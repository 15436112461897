import * as React from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useFormContext,
  UseFormReturn,
} from "react-hook-form";
import { Prompt } from "react-router-dom";

import { FORM_ERROR, FormErrors } from "../formUtils";

export function GeneralFormErrors() {
  const { formState } = useFormContext();
  const generalFormError = (formState.errors as FieldValues | FormErrors)[FORM_ERROR];
  return generalFormError ? (
    <Alert status="error" mb={4} borderRadius="md">
      <AlertIcon />
      {generalFormError.message}
    </Alert>
  ) : null;
}

interface IFormProps<FormValues> extends UseFormReturn<FormValues> {
  children: React.ReactNode;
  onSubmit: SubmitHandler<FormValues>;
  shouldPromptOnDirty?: boolean;
}

export default function Form<FormValues>(props: IFormProps<FormValues>) {
  const { children, onSubmit, shouldPromptOnDirty = true, ...formProps } = props;

  return (
    <FormProvider {...formProps}>
      {shouldPromptOnDirty && (
        <Prompt
          when={
            formProps.formState.isDirty &&
            // allows forms to redirect during form submission. E.g. taking the user to a success page.
            // `isSubmitting` doesn't become false again until after the onSubmit
            !formProps.formState.isSubmitting &&
            // `isDirty` does a deep comparison with default values so it might return the wrong value if the form is misconfigured
            Object.keys(formProps.formState.dirtyFields).length > 0
          }
          message="Your changes have not been saved. Are you sure you want to leave?"
        />
      )}

      <form
        onSubmit={(e) => {
          // Any manually set errors much be cleared manually (https://github.com/react-hook-form/react-hook-form/issues/70#issuecomment-753264618)
          formProps.clearErrors();
          formProps.handleSubmit(onSubmit)(e);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
}
