import { Box, Button, Heading } from "@chakra-ui/react";
import { Launch } from "@material-ui/icons";

import * as C from "@svix/common/constants";

export default function EventCatalogEmptyState() {
  return (
    <Box
      maxWidth={800}
      margin="0 auto"
      p={4}
      textAlign="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <Heading as="h2" size="md" mt={4} mb={2}>
        No Event Types
      </Heading>
      <Button
        as="a"
        mt={4}
        size="sm"
        variant="outline"
        rightIcon={<Launch fontSize="small" />}
        target="_blank"
        href={C.docs.eventTypes.index}
      >
        Learn more about event types
      </Button>
    </Box>
  );
}
