import { ButtonProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import Button from "./Button";

interface IButtonProps extends ButtonProps {
  showLoading?: boolean;
}

export default function SubmitButton(props: IButtonProps) {
  const { children, showLoading, ...rest } = props;
  const { formState } = useFormContext();

  return (
    <Button
      type="submit"
      isDisabled={formState.isSubmitting}
      isLoading={showLoading && formState.isSubmitting}
      {...rest}
    >
      <span>{children}</span>
    </Button>
  );
}
