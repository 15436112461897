import * as React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import { FieldError, useController } from "react-hook-form";
import ResizeTextarea from "react-textarea-autosize";

function getErrorMessage(error: FieldError[] | FieldError | undefined): string {
  if (!error) {
    return "";
  }
  if (Array.isArray(error)) {
    return error
      .map((e) => getErrorMessage(e))
      .filter((e) => e.length > 0)
      .join(", ");
  }
  if (error.message) {
    return error.message;
  }
  if (error.type === "required") {
    return "Required";
  }
  if (error.type === "maxLength") {
    return "Max length exceeded";
  }
  return "Invalid";
}

export interface ITextAreaFieldProps extends TextareaProps {
  control: any;
  name: string;
  label?: React.ReactNode | string;
  helperText?: string;
  required?: boolean;
}

export default function TextAreaField({
  control,
  helperText,
  label,
  name,
  required,
  ...inputProps
}: ITextAreaFieldProps) {
  const {
    field: { ref, ...controlProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: "",
  });

  const errorMessage = getErrorMessage(error);

  return (
    <FormControl isInvalid={invalid} id={name} isRequired={required}>
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {label && <FormLabel>{label}</FormLabel>}
      <Textarea
        as={ResizeTextarea}
        resize="vertical"
        variant="filled"
        {...inputProps}
        {...controlProps}
      />
      {helperText && !error && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
