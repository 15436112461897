import { useState } from "react";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBoolean,
  useColorModeValue,
  Flex,
  Box,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router";

import useKeyboardShortcut from "@svix/common/hooks/keyboardShortcut";
import { logError } from "@svix/common/logger";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";

export default function FilterMenu() {
  const user = useAppSelector((state) => state.auth.user)!;
  const [showFilterMenu, setShowFilterMenu] = useBoolean();
  const history = useHistory();
  const toolbarColor = useColorModeValue("white", "gray.800");

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  useKeyboardShortcut("j", null, setShowFilterMenu.on);

  const formCtx = useForm({
    defaultValues: { messageID: "" },
  });

  const jumpToMessageID = useWatch({
    control: formCtx.control,
    name: "messageID",
    defaultValue: "",
  });

  const submitForm = async (form: { messageID: string }) => {
    setLoading(true);
    try {
      const dh = getSvix();
      await dh.message.get(user.app.id, form.messageID);
      history.push(routeResolver.getRoute("messages._id", { msgId: form.messageID }));
    } catch (e) {
      if (e.code === 404) {
        setError("Invalid Message ID");
      } else {
        setError("An error occurred verifying the Message ID");
        logError(e);
      }
    }
    setLoading(false);
  };

  return (
    <Popover
      isOpen={showFilterMenu}
      onClose={setShowFilterMenu.off}
      placement="bottom-end"
      matchWidth
    >
      <PopoverTrigger>
        <Button
          onClick={setShowFilterMenu.toggle}
          bgColor={toolbarColor}
          colorScheme="gray"
          variant="outline"
          size="sm"
        >
          Jump to Message
        </Button>
      </PopoverTrigger>
      <PopoverContent mx={2} width="auto">
        <PopoverArrow />
        <PopoverBody p={3}>
          <Form onSubmit={submitForm} {...formCtx}>
            <Flex alignItems="center">
              <Box flexGrow={0}>
                <TextField
                  control={formCtx.control}
                  name="messageID"
                  type="string"
                  w="21.1em"
                  placeholder="Enter the message ID or event ID"
                  mr={2}
                />
              </Box>
              <SubmitButton
                disabled={jumpToMessageID.length === 0}
                isLoading={loading}
                size="sm"
              >
                Go
              </SubmitButton>
            </Flex>
          </Form>
          {error && !loading && (
            <Alert status="warning" mt="1em">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
