import { Alert, AlertTitle, AlertIcon, AlertDescription, Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import { routeResolver } from "src/App";

export default function AuthedNotFound() {
  return (
    <Alert
      bg="transparent"
      colorScheme="gray"
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      pt="5em"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mx={0} mt={6} mb={4} fontSize="4xl">
        404
      </AlertTitle>
      <AlertDescription maxWidth="sm">This page doesn't exist.</AlertDescription>

      <Button variant="solid" mt={4} as={RouterLink} to={routeResolver.getRoute("home")}>
        Return to main page
      </Button>
    </Alert>
  );
}
