import { Alert, AlertTitle, AlertIcon, AlertDescription, Button } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface IResourceNotFoundProps {
  resourceName: string;
  to: string;
}

export default function ResourceNotFound(props: IResourceNotFoundProps) {
  const { resourceName, to } = props;

  return (
    <Alert
      bg="transparent"
      colorScheme="gray"
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      pt="5em"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mx={0} mt={6} mb={4} fontSize="4xl">
        404
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        We weren't able to find that {resourceName}.
      </AlertDescription>

      <Button variant="solid" mt={4} as={RouterLink} to={to}>
        Return to {resourceName} list
      </Button>
    </Alert>
  );
}
