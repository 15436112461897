import { forwardRef } from "react";
import { ListItem, ListItemProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function ListItemLink(props: ListItemProps & { to: string }) {
  const { children, to, ...rest } = props;

  let CustomLink;
  if (to.startsWith("http") || to.startsWith("mailto:")) {
    CustomLink = forwardRef((props, ref: any) => (
      <a href={to} ref={ref} target="_blank" rel="noreferrer" {...props} />
    ));
  } else {
    CustomLink = forwardRef((props, ref: any) => <Link to={to} ref={ref} {...props} />);
  }

  return (
    <ListItem
      as={CustomLink}
      {...rest}
      _hover={{ bg: "background.hover" }}
      p={3}
      borderRadius={8}
    >
      {children}
    </ListItem>
  );
}
