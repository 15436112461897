import { useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useHistory } from "react-router";

import { logError, logInfo } from "@svix/common/logger";
import { getApiError, isApiError } from "@svix/common/utils";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";

import { routeResolver } from "src/App";
import { useAppDispatch, useAppSelector, useStateSelector } from "src/hooks/store";
import { popError } from "src/store/errors";

export default function GlobalErrors() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const error = useStateSelector((state) => state.errors[0]) as Error | undefined;
  const isEmbed = useAppSelector((state) => state.embedConfig.isEmbed);

  useEffect(() => {
    if (error) {
      if (error.message === "Failed to fetch") {
        // No need to log this - temporary network issue locally
      } else if (isApiError(error) && error.body.code === "invalid_schema") {
        // No need to log it, it's an error that's normal if the schema is bad
      } else if (isSessionExpiredError(error)) {
        logInfo(error);
      } else {
        logError(error);
      }
    }
  }, [error]);

  if (!error) {
    return null;
  }

  return (
    <>
      {isSessionExpiredError(error) ? (
        <ConfirmationDialog
          title="Your session has expired"
          isOpen
          labelOk="Okay"
          onOk={() => {
            history.push(routeResolver.getRoute("login"));
          }}
        >
          <Box mt={2}>
            <Text>
              {isEmbed
                ? "Refresh your browser to renew your session."
                : "Please reopen the App Portal."}
            </Text>
          </Box>
        </ConfirmationDialog>
      ) : (
        <ConfirmationDialog
          title="An error has occurred"
          isOpen
          labelOk="Okay"
          onOk={() => {
            dispatch(popError());
          }}
        >
          {getApiError(error)}
        </ConfirmationDialog>
      )}
    </>
  );
}

function isSessionExpiredError(e: Error): boolean {
  return isApiError(e) && e.body.code === "authentication_failed";
}
