import * as React from "react";
import { Box, Flex, Stat, StatLabel } from "@chakra-ui/react";

interface IStatProps {
  id?: string;
  children: React.ReactNode;
  name: React.ReactNode | string;
  cta?: React.ReactNode;
}

export default function StatWrapper(props: IStatProps) {
  const { id, name, cta, children } = props;

  return (
    <Stat id={id} flex="unset">
      <Flex alignItems="center" mb={2}>
        <StatLabel fontSize="sm">{name}</StatLabel>
        {cta}
      </Flex>
      <Box fontSize="sm" lineHeight={2}>
        {children}
      </Box>
    </Stat>
  );
}
