import { Flex, FlexProps, List, useColorModeValue } from "@chakra-ui/react";
import { useLocation, Link, useRouteMatch } from "react-router-dom";

import { useAppSelector } from "src/hooks/store";
import { routeResolver } from "../App";

interface LinkTabProps extends FlexProps {
  to: string;
}

function LinkTab(props: LinkTabProps) {
  const { to, ...rest } = props;
  const match = useRouteMatch(to);
  const matchColor = useColorModeValue("navigation.accent", "navigation.accent");

  return (
    <Flex
      as={Link}
      borderBottom="2px solid"
      borderBottomColor={match ? matchColor : "transparent"}
      to={to}
      color={match ? matchColor : undefined}
      py={3}
      px={4}
      transition="0.1s color ease-in, 0.1s border-color ease-in"
      {...rest}
    />
  );
}

export default function NavigationTabs(props: { noGutters: boolean }) {
  const location = useLocation();
  const value = location.pathname.startsWith(routeResolver.getRoute("endpoints")) ? 0 : 1;
  const { hideEventTypes } = useAppSelector((state) => state.embedConfig);

  return (
    <Flex
      value={value}
      colorScheme="brand"
      position="sticky"
      id="navigation-bar"
      top={0}
      bgColor="background.primary"
      left={0}
      right={0}
      zIndex={1}
      px={props.noGutters ? 0 : 8}
    >
      <List display="flex" flexDir="row">
        <LinkTab to={routeResolver.getRoute("endpoints")}>Endpoints</LinkTab>
        {!hideEventTypes && (
          <LinkTab to={routeResolver.getRoute("event-types")}>Event Catalog</LinkTab>
        )}
        <LinkTab to={routeResolver.getRoute("messages")}>Logs</LinkTab>
        <LinkTab to={routeResolver.getRoute("activity")}>Activity</LinkTab>
      </List>
    </Flex>
  );
}
