import { useEffect } from "react";
import { Divider, Box, Flex, Stack, HStack, Text } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForwardIos } from "@material-ui/icons";
import { capitalize } from "lodash";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { EnvironmentSettingsApi } from "svix/dist/openapi";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix, SinkIn, SinksApi, SinkTypeName } from "src/api";
import { routeResolver } from "src/App";
import { useAllEventTypes, useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import sqsIcon from "src/img/sinks/aws-sqs.svg";
import rabbitMQIcon from "src/img/sinks/rabbitmq.svg";
import ChannelsAutocomplete from "../../Endpoint/properties/ChannelsAutosuggest";

const schema = yup.object().shape({
  description: yup.string(),
  filterTypes: yup.mixed(),
  channels: yup.array().of(yup.string()).max(3).nullable(),
  rateLimit: yup
    .number()
    .nullable()
    .transform((_, val) => (val !== "" && val !== null ? Number(val) : null)),
});

const DEFAULT_VALUES = {
  filterTypes: [] as string[],
};

interface SinkButton {
  name: string;
  description: string;
  type: SinkTypeName;
  icon: string;
}

export const SinkButtons: SinkButton[] = [
  {
    name: "RabbitMQ",
    type: "rabbitMQ",
    description: "Publish messages directly to a RabbitMQ queue",
    icon: rabbitMQIcon,
  },
  {
    name: "SQS",
    type: "sqs",
    description: "Publish messages directly to an SQS queue",
    icon: sqsIcon,
  },
];

interface SinkCreateFormProps {
  type: SinkTypeName;
}

export function SinkCreateForm(props: SinkCreateFormProps) {
  const { type: sinkType } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const stringsOverride = useAppSelector((state) => state.embedConfig.stringsOverrides);
  const history = useHistory();
  const formCtx = useForm<SinkIn>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });

  const { data: orgSettings } = useAppQuery(["orgSettings"], async () => {
    const sv = getSvix();
    const config = sv._configuration;
    const api = new EnvironmentSettingsApi(config);
    return api.v1EnvironmentGetSettings({});
  });

  const { data: availableEvents } = useAllEventTypes();

  async function onAddSink(form: SinkIn) {
    if (form.filterTypes && form.filterTypes.length === 0) {
      delete form["filterTypes"];
    }
    if (form.channels && form.channels.length === 0) {
      delete form["channels"];
    }

    try {
      const sink: SinkIn = {
        ...form,
        type: sinkType as any,
      };
      const sinksApi = SinksApi();
      const res = await sinksApi.createSink(user.app.id, sink);
      history.push(routeResolver.getRoute("sinks._id", { endpId: res.id }));
    } catch (e) {
      setErrors(formCtx.setError, e);
    }
  }

  const sqsQueueDsn = formCtx.watch("queueDsn");
  useEffect(() => {
    const maybeRegion = sqsQueueDsn && sqsQueueDsn.split(".")[1];
    if (maybeRegion) {
      formCtx.setValue("region", maybeRegion);
    }
  }, [formCtx, sqsQueueDsn]);

  return (
    <Form onSubmit={onAddSink} {...formCtx}>
      <Box maxW="50em">
        <Stack spacing={5}>
          {sinkType === "rabbitMQ" && (
            <>
              <TextField
                control={formCtx.control}
                name="uri"
                label="AMQPS URL"
                type="url"
                isRequired
                placeholder="e.g. amqps://username:password@rattlesnake.rmq.cloudamqp.com/qpfqmzgf"
                helperText={
                  <span>
                    Configure a RabbitMQ queue endpoint. Make sure it contains username
                    and password for authentication.
                  </span>
                }
              />
              <TextField
                control={formCtx.control}
                name="routingKey"
                label="Routing key"
                helperText="It will be sent with each message published to the queue."
                type="text"
                isRequired
                placeholder="e.g. key-1"
              />
            </>
          )}

          {sinkType === "sqs" && (
            <>
              <TextField
                control={formCtx.control}
                name="queueDsn"
                label="Queue DSN"
                type="url"
                isRequired
                placeholder="e.g. https://sqs.us-east-1.amazonaws.com/635461488753/my-queue"
                helperText={
                  <span>
                    Make sure this URL is accessible from the public internet, from
                    outside AWS.
                  </span>
                }
              />
              <TextField
                control={formCtx.control}
                name="region"
                label="Region"
                type="text"
                isRequired
                placeholder="e.g. us-east-1"
              />

              <Divider />

              <Text>
                Enter the access key and secret pair associated with an IAM user with
                write-access to the queue.
              </Text>
              <TextField
                control={formCtx.control}
                name="accessKey"
                label="Access Key"
                type="text"
                placeholder="AKIAZH5DU2BYQBJ3BKEF"
                isRequired
              />

              <TextField
                control={formCtx.control}
                name="secretKey"
                label="Access Key Secret"
                placeholder="****************"
                type="password"
                isRequired
              />
            </>
          )}

          <Divider />
          <EventsList
            availableEvents={availableEvents?.data || []}
            control={formCtx.control}
            name="filterTypes"
            label={
              <Flex alignItems="center" justifyContent="space-between">
                <span>Subscribe to events</span>
                <StyledLink
                  fontSize="sm"
                  display="flex"
                  alignItems="center"
                  color="interactive.accent"
                  to={routeResolver.getRoute("event-types")}
                >
                  Event Catalog
                  <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
                </StyledLink>
              </Flex>
            }
            emptyState="Receiving all events."
          />
          {orgSettings?.enableChannels && (
            <ChannelsAutocomplete
              label="Channels"
              name="channels"
              control={formCtx.control}
              helperText={`${capitalize(stringsOverride.channelsMany)} are an
              extra dimension of filtering messages orthogonal to event types.
              They are case-sensitive and only messages with the corresponding
              ${stringsOverride.channelsOne} will be sent to this endpoint.`}
            />
          )}

          <Divider />
        </Stack>
        <GeneralFormErrors />
        <HStack mt={4} spacing={4}>
          <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("endpoints")}>
            Cancel
          </Button>
          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Create</SubmitButton>
        </HStack>
      </Box>
    </Form>
  );
}
