import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { SvixRegion } from "@svix/common/constants";

import type { RootState, AppDispatch } from "../store";
import { Application } from "../store/applications";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useStateSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Note: DO NOT use this hook outside of the RequireApplication component.
 * This hook uses a type assertion that can fail when used outside the RequireApplication component.
 */
export const useAppSelector: TypedUseSelectorHook<Application> = (selector) => {
  return useSelector((state: RootState) => {
    return selector(state.applications.apps[state.applications.appId!]);
  });
};

export const useAppIdSelector = () =>
  useSelector((state: RootState) => state.applications.appId);

export const useIsAppAvailable = (): boolean => {
  return useSelector((state: RootState) => {
    if (state.applications.appId && state.applications.appId in state.applications.apps) {
      return true;
    } else {
      return false;
    }
  });
};

export const useRegion = (): SvixRegion => {
  return useAppSelector((app) => app.auth.user!.region);
};
