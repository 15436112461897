import {
  Box,
  Stack,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  useColorModeValue,
} from "@chakra-ui/react";

interface IOption {
  label: string;
  value: string;
}

interface IRadioButtonProps extends UseRadioProps {
  label: string;
}

interface IRadioButtonsProps {
  value: string;
  onChange: (val: string) => void;
  options: IOption[];
}

function RadioButton(props: IRadioButtonProps) {
  const { label, ...inputProps } = props;
  const { getInputProps, getCheckboxProps } = useRadio(inputProps);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box as="label">
      <input {...input} onChange={input.onChange} />
      <Box
        {...checkbox}
        bgColor="background.primary"
        border="1px solid"
        borderColor={borderColor}
        cursor="pointer"
        borderRadius="full"
        _checked={{
          bgColor: "interactive.accent",
          borderColor: "interactive.accent",
          color: "white",
          fontWeight: "semibold",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        _hover={{
          bgColor: borderColor,
        }}
        sx={{
          "&[data-checked]:hover": {
            bgColor: "interactive.accentHover",
          },
        }}
        fontSize="xs"
        px={3}
        py={1}
      >
        {props.label}
      </Box>
    </Box>
  );
}

export default function RadioButtons(props: IRadioButtonsProps) {
  const { onChange, options, value } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    onChange,
    defaultValue: value,
  });

  return (
    <Stack
      direction="row"
      wrap="wrap"
      sx={{ gap: "0.5rem" }}
      spacing={0}
      {...getRootProps()}
    >
      {options.map(({ value, label }) => (
        <RadioButton key={value} label={label} {...getRadioProps({ value })} />
      ))}
    </Stack>
  );
}
