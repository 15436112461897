import { Link as StyledLink, LinkProps } from "@chakra-ui/react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

type ICustomLinkProps = Omit<LinkProps & RouterLinkProps, "to"> & { to?: string };

export default function Link(props: ICustomLinkProps) {
  return (
    <StyledLink
      as={props.to ? RouterLink : undefined}
      color="interactive.accent"
      {...props}
    />
  );
}
