import { useEffect } from "react";

import { isMacos } from "../utils";

export type Modifier = "mod" | "command" | "control";

const INPUT_TAGS = ["input", "textarea", "select"];

export default function useKeyboardShortcut(
  key: string,
  modifier: Modifier | null,
  handler: () => void
) {
  useEffect(() => {
    const eventHandler = (e: KeyboardEvent) => {
      if (
        e.target instanceof HTMLElement &&
        (INPUT_TAGS.includes(e.target.tagName.toLowerCase()) ||
          e.target.isContentEditable)
      ) {
        // Don't swallow events that were meant for input elements
        return;
      }

      if (
        e.key === key &&
        ((modifier === "command" && e.metaKey) ||
          (modifier === "control" && e.ctrlKey) ||
          (modifier === "mod" && e.metaKey && isMacos()) ||
          (modifier === "mod" && e.ctrlKey && !isMacos()) ||
          modifier === null)
      ) {
        e.preventDefault();
        handler();
      }
    };
    window.addEventListener("keydown", eventHandler);
    return () => {
      window.removeEventListener("keydown", eventHandler);
    };
  }, [key, modifier, handler]);
}
