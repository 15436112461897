import * as React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { Add, Close } from "@material-ui/icons";
import { useQuery, useQueryClient } from "react-query";
import { EndpointHeadersOut, EndpointOut, HTTPValidationError } from "svix";

import Card from "@svix/common/widgets/Card";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import { useLoadingManual } from "src/utils";

interface SortedHeaders {
  header: string;
  value?: string;
  isSensitive: boolean;
}

function getErrorMessage(error: any | undefined): string {
  if (!error) {
    return "";
  }
  if (error.body instanceof HTTPValidationError) {
    return error.body.detail[0].msg;
  }
  return String(error.message);
}

function getSortedHeaders(headers: EndpointHeadersOut | undefined) {
  if (!headers) {
    return [];
  }

  const sorted: SortedHeaders[] = Object.entries(headers.headers).map(([k, v]) => ({
    header: k,
    value: v,
    isSensitive: false,
  }));

  for (const sensitive of headers.sensitive) {
    sorted.push({ header: sensitive, isSensitive: true });
  }

  return sorted.sort((a, b) => a.header.localeCompare(b.header));
}

interface ICustomHeadersProps {
  endpoint: EndpointOut;
}

type LegacyRef = React.MutableRefObject<HTMLInputElement | null>;

export default function CustomHeaders(props: ICustomHeadersProps) {
  const { endpoint } = props;
  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);
  const user = useAppSelector((state) => state.auth.user)!;
  const [error, setError] = React.useState<Error | undefined>();
  const queryKey = ["endpoints", endpoint.id, "customHeaders"];

  const keyField = React.useRef() as LegacyRef;
  const valueField = React.useRef() as LegacyRef;
  const queryClient = useQueryClient();

  const { data: customHeaders } = useQuery(queryKey, () => {
    const api = getSvix();
    return api.endpoint.getHeaders(user.app.id, endpoint.id);
  });
  const rows = getSortedHeaders(customHeaders);

  const [, , maybeAddItem] = useLoadingManual(async () => {
    setError(undefined);
    const k = keyField.current?.value;
    const v = valueField.current?.value;

    if (k && v && k.trim() && v.trim()) {
      keyField.current!.value = "";
      valueField.current!.value = "";
      keyField.current!.focus();
      queryClient.setQueryData(queryKey, (value) => {
        (value as EndpointHeadersOut).headers[k] = v;
        return value;
      });

      try {
        const api = getSvix();
        await api.endpoint.patchHeaders(user.app.id, endpoint.id, {
          headers: { [k]: v },
        });
      } catch (err) {
        setError(err as Error);
      } finally {
        queryClient.invalidateQueries(queryKey);
      }
    }
  }, []);

  const [, , removeHeading] = useLoadingManual(async (header: string) => {
    setError(undefined);
    queryClient.setQueryData(queryKey, (value) => {
      delete (value as EndpointHeadersOut).headers[header];
      return value;
    });

    try {
      const api = getSvix();
      await api.endpoint.patchHeaders(user.app.id, endpoint.id, {
        headers: { [header]: null },
      } as any); // XXX: cast to any until type gets fixed in svix-libs
    } catch (err) {
      setError(err as Error);
    } finally {
      queryClient.invalidateQueries(queryKey);
    }
  }, []);

  return (
    <Card maxH={460} overflowY="scroll" title="Custom Headers">
      <Stack divider={<StackDivider borderColor="background.modifier.border" />}>
        {rows.map((row, i) => (
          <HStack key={i}>
            <Text w="100%">{row.header}</Text>
            {row.isSensitive ? (
              <Flex w="100%">
                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              </Flex>
            ) : (
              <Text w="100%">{row.value}</Text>
            )}
            {!isReadOnly && (
              <Tooltip
                hasArrow
                aria-label="Remove Header"
                label="Remove Header"
                placement="top"
              >
                <IconButton
                  isRound
                  size="sm"
                  variant="ghost"
                  colorScheme="gray"
                  icon={<Close style={{ fontSize: 16 }} />}
                  aria-label="Remove Header"
                  onClick={() => removeHeading(row.header)}
                />
              </Tooltip>
            )}
          </HStack>
        ))}
        {!isReadOnly && (
          <FormControl isInvalid={!!error} id="customHeaders">
            <HStack>
              <Input
                isInvalid={false}
                ref={keyField}
                id="key"
                variant="filled"
                placeholder="Key"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    maybeAddItem();
                  }
                }}
              />
              <Input
                ref={valueField}
                id="value"
                isInvalid={false}
                variant="filled"
                placeholder="Value"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    maybeAddItem();
                  }
                }}
              />
              <Tooltip
                hasArrow
                aria-label="Add Header"
                label="Add Header"
                placement="top"
              >
                <IconButton
                  size="sm"
                  variant="solid"
                  colorScheme="brand"
                  icon={<Add style={{ fontSize: 16 }} />}
                  aria-label="Add item"
                  onClick={maybeAddItem}
                />
              </Tooltip>
            </HStack>
            <FormErrorMessage>{getErrorMessage(error)}</FormErrorMessage>
          </FormControl>
        )}
      </Stack>
    </Card>
  );
}
