import { useState, useCallback } from "react";
import { Image, Stack, Text } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { EndpointIn } from "svix";

import Button from "@svix/common/widgets/Button";

import { useHubspotOauth } from "src/hooks/integrations/hubspot";
import hubspotLogo from "src/img/integrations/hubspot-icon.svg";

export type HubspotIntegrationProps = {
  formCtx: UseFormReturn<EndpointIn, any>;
  refreshToken?: string;
  setRefreshToken: (refreshToken: string) => void;
};

export default function HubspotIntegration(props: HubspotIntegrationProps) {
  const { formCtx, setRefreshToken } = props;
  const [connectedToHubspot, setConnectedToHubspot] = useState(false);
  const onRefreshTokenReceived = useCallback(
    (refreshToken: string) => {
      formCtx.setValue("url", "https://api.hubapi.com");
      setRefreshToken(refreshToken);
      setConnectedToHubspot(true);
    },
    [formCtx, setRefreshToken, setConnectedToHubspot]
  );

  const { onConnect, error: oauthError } = useHubspotOauth(onRefreshTokenReceived);

  return (
    <Stack>
      <Text fontWeight="semibold">Connect</Text>
      <Button
        onClick={onConnect}
        isDisabled={connectedToHubspot}
        variant="outline"
        leftIcon={<Image src={hubspotLogo} height="1.4em" />}
        w="fit-content"
      >
        {connectedToHubspot ? "Connected" : "Connect to Hubspot"}
      </Button>
      {oauthError && <Text color="red.500">{oauthError}</Text>}
    </Stack>
  );
}
