import { lazy, useState, Suspense } from "react";
import {
  ButtonGroup,
  Box,
  Flex,
  Code,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  IconButton,
  MenuItem,
  Skeleton,
  Stack,
  Switch,
  Tag,
  Table,
  Tooltip,
  Tbody,
  Text,
  Thead,
  Td,
  Tr,
  Th,
  useBoolean,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight, ExpandMore, Replay } from "@material-ui/icons";
import { capitalize } from "lodash";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  ListResponseMessageOut,
  MessageAttemptEndpointOut,
  MessageEndpointOut,
  MessageOut,
  MessageStatus,
  ApiException,
} from "svix";
import {
  EnvironmentSettingsApi,
  MessageApi,
  MessageAttemptApi,
  HttpErrorOut,
  MessageRawPayloadOut,
} from "svix/dist/openapi";

import { formatDateTime, humanize } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import MessageStatusDisplay from "@svix/common/widgets/MessageStatusDisplay";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";
import Stat from "@svix/common/widgets/Stat";
import { TableContainer } from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";
import TableRowMenu from "@svix/common/widgets/TableRowMenu";
import TimestampTableCell from "@svix/common/widgets/TimestampTableCell";

import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { isEE, useLoadingManual } from "src/utils";
import MessageStatusFilter from "./Endpoint/MessageStatusFilter";
import { getSvix } from "../api";

const CodeBlock = lazy(() => import("@svix/common/widgets/Code"));
const JsonViewer = lazy(() => import("@svix/common/widgets/JsonViewer"));

interface ResponseViewerPropsType {
  code: string;
}

function ResponseViewer(props: ResponseViewerPropsType) {
  const { code } = props;

  let jsonCode: Record<string, unknown> | undefined;
  if (typeof code === "string") {
    try {
      jsonCode = JSON.parse(code);
    } catch (e) {
      jsonCode = undefined;
    }
  } else {
    jsonCode = code;
  }

  if (jsonCode === undefined) {
    return <p>{code || "---"}</p>;
  }

  return (
    <Suspense fallback={<JsonViewerPlaceholder />}>
      <JsonViewer code={jsonCode} />
    </Suspense>
  );
}

function JsonViewerPlaceholder() {
  return <Skeleton w="100%" h="6em" />;
}

function MessageAttemptTableRow(props: {
  msg: MessageOut;
  endpoint: MessageEndpointOut;
  attempt: MessageAttemptEndpointOut;
}) {
  const [isOpen, setOpen] = useState(false);
  const user = useAppSelector((state) => state.auth.user)!;
  const { msg, attempt, endpoint } = props;

  const [isResending, , resend] = useLoadingManual(async () => {
    const dh = getSvix();
    await dh.messageAttempt.resend(user.app.id, msg.id, endpoint.id);
  }, []);

  const { data: attemptData } = useAppQuery(
    ["message", msg.id, "attempt", attempt.id],
    async () => {
      const dh = getSvix();
      const data = await dh.messageAttempt.get(user.app.id, msg.id, attempt.id);
      return data;
    },
    {
      enabled: isOpen,
    }
  );

  const {
    data: headers,
    isLoading: isLoadingHeaders,
    error: headersError,
  } = useAppQuery(
    ["message", msg.id, "attempt", attempt.id, "headers"],
    async () => {
      const dh = getSvix();
      const config = dh._configuration;
      const attemptApi = new MessageAttemptApi(config);
      const data = await attemptApi.v1MessageAttemptGetHeaders({
        appId: user.app.id,
        msgId: msg.id,
        attemptId: attempt.id,
      });
      return data.sentHeaders;
    },
    {
      enabled: isOpen,
    }
  );

  let nextAttempt = "";
  if (endpoint.nextAttempt) {
    nextAttempt =
      attempt.status === MessageStatus.Sending
        ? `Attempt started at ${formatDateTime(endpoint.nextAttempt, { withMs: true })}`
        : `Next attempt at ${formatDateTime(endpoint.nextAttempt, { withMs: true })}`;
  }

  return (
    <>
      <Tr>
        <Td as="th" scope="row" alignItems="baseline" p={0}>
          <IconButton
            aria-label="more"
            size="sm"
            onClick={() => setOpen(!isOpen)}
            variant="ghost"
            mr={4}
            ml={2}
          >
            {isOpen ? <ExpandMore /> : <ChevronRight />}
          </IconButton>
        </Td>
        <Td p={0} minW="120px">
          <MessageStatusDisplay status={attempt.status} tooltip={nextAttempt} />
        </Td>
        <TableCell mono isTruncated width="40%" maxW={0} tooltip={attempt.url}>
          {attempt.url}
        </TableCell>
        <TimestampTableCell isNumeric ts={attempt.timestamp} />
        <TableRowMenu>
          <Tooltip
            label="The endpoint URL has changed since this message was sent. The message will be sent to the updated endpoint."
            isDisabled={attempt.url === endpoint.url}
          >
            <MenuItem
              isDisabled={isResending}
              onClick={resend}
              icon={<Replay fontSize="small" />}
            >
              Resend
            </MenuItem>
          </Tooltip>
        </TableRowMenu>
      </Tr>

      {isOpen && (
        <>
          <Tr>
            <TableCell as={Th} scope="row" colSpan={2}>
              HTTP Response Code
            </TableCell>
            <TableCell colSpan={4}>
              {attempt.responseStatusCode || <em>No Response</em>}
            </TableCell>
          </Tr>
          <Tr>
            <TableCell as={Th} scope="row" colSpan={2}>
              Response
            </TableCell>
            <TableCell colSpan={4}>
              {attemptData ? (
                <ResponseViewer code={attemptData.response} />
              ) : (
                <LoadingIndicator />
              )}
            </TableCell>
          </Tr>
          <Tr>
            <TableCell as={Th} scope="row" colSpan={4}>
              Webhook Headers
            </TableCell>
          </Tr>
          {isLoadingHeaders && (
            <Tr>
              <TableCell scope="row" colSpan={4}>
                <LoadingIndicator />
              </TableCell>
            </Tr>
          )}
          {headersError && (
            <Tr>
              <TableCell scope="row" colSpan={4}>
                <Text color="red.500">Could not load headers for this attempt.</Text>
              </TableCell>
            </Tr>
          )}
          {headers &&
            Object.keys(headers)
              .sort()
              .map((key, i) => (
                <Tr key={i}>
                  <TableCell scope="row" colSpan={2}>
                    <pre>{key}</pre>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <pre>{headers[key]}</pre>
                  </TableCell>
                </Tr>
              ))}
        </>
      )}
    </>
  );
}

export default function MessageScreen() {
  const [iteratorStack, setIteratorStack] = useState<string[]>([]);
  const [filterStatus, setFilterStatus] = useState<MessageStatus>();
  const { msgId } = useParams<{ msgId: string }>();
  const user = useAppSelector((state) => state.auth.user)!;
  const stringsOverride = useAppSelector((state) => state.embedConfig.stringsOverrides);
  const queryClient = useQueryClient();

  const { data: orgSettings } = useAppQuery(
    ["orgSettings"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const api = new EnvironmentSettingsApi(config);
      return api.v1EnvironmentGetSettings({});
    },
    {
      // since org settings should not likely to change frequently, don't refetch
      // on re-mount
      staleTime: Infinity,
    }
  );

  interface WithErrorProps {
    error: { code: number } | null;
    data: MessageOut | undefined;
  }
  const { error, data: msg }: WithErrorProps = useAppQuery(
    ["messages", msgId],
    async () => {
      const dh = getSvix();
      return dh.message.get(user.app.id, msgId);
    },
    {
      placeholderData: () =>
        queryClient
          .getQueryData<ListResponseMessageOut>("messages")
          ?.data.find((d) => d.id === msgId),
    }
  );

  const { data: endpoints } = useAppQuery(["messages", msgId, "endpoints"], async () => {
    const dh = getSvix();
    const map = new Map<string, MessageEndpointOut>();
    const res = await dh.messageAttempt.listAttemptedDestinations(user.app.id, msgId, {
      iterator: iteratorStack[0],
    });
    // FIXME: need to iterate all of them.
    for (const endp of res.data) {
      map.set(endp.id, endp);
    }
    return map;
  });

  const { data: attempts } = useAppQuery(
    ["messages", msgId, "attempts", filterStatus],
    async () => {
      const dh = getSvix();
      return dh.messageAttempt.list(user.app.id, msgId, {
        iterator: iteratorStack[0],
        limit: 20,
        status: filterStatus,
      });
    }
  );

  function prevPage() {
    if (iteratorStack.length > 0) {
      setIteratorStack(iteratorStack.slice(1));
    }
  }

  function nextPage() {
    if (attempts?.iterator) {
      setIteratorStack([attempts.iterator, ...iteratorStack]);
    }
  }

  const pageLength = Array.isArray(attempts?.data) ? attempts?.data.length : 0;

  return (
    <>
      <MetaTitle path={[msg?.eventId ?? humanize(msgId), "Logs", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("messages")}>
            Message Logs
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={msgId} uid={msg?.eventId} />
        </Breadcrumbs>
      </PageToolbar>

      {error?.code === 404 ? (
        <>
          <Heading as="h1" size="md" mb={6}>
            404: Not Found
          </Heading>
          <Text>
            No message with the ID <Code>{msgId}</Code> exists.
          </Text>
        </>
      ) : (
        <>
          <Grid
            gridTemplateColumns={{
              sm: "minmax(0, 1fr)",
              md: "minmax(0, 3fr) minmax(240px, 1fr)",
            }}
            gap={8}
          >
            <Box>
              <Flex alignItems="center">
                <Box mb={6}>
                  <Skeleton fadeDuration={0} isLoaded={!!msg}>
                    <Heading as="h1" size="md">
                      {msg?.eventType ?? "No event type"}
                    </Heading>
                  </Skeleton>
                </Box>
              </Flex>
              <PayloadViewer msg={msg} msgId={msgId} />
            </Box>
            <Stack spacing={4}>
              <Stat name="Created at">{msg && formatDateTime(msg.timestamp)}</Stat>
              <Divider />
              {orgSettings?.enableChannels && (
                <Stat name={capitalize(stringsOverride.channelsMany)}>
                  {msg?.channels?.join(", ") ?? "None"}
                </Stat>
              )}
              {!isEE && (msg?.tags?.length ?? 0) > 0 && (
                <Stat name="Tags">{msg?.tags?.join(", ") ?? "None"}</Stat>
              )}
            </Stack>
          </Grid>

          <Flex alignItems="center" mb={4} mt={6}>
            <Heading as="h2" size="sm">
              Webhook Attempts
            </Heading>
            <Flex flexGrow={1} />
            <MessageStatusFilter value={filterStatus} onChange={setFilterStatus} />
          </Flex>

          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>Status</Th>
                  <Th>URL</Th>
                  <Th isNumeric>Timestamp</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {endpoints &&
                  msg &&
                  attempts?.data.map((attempt) => (
                    <MessageAttemptTableRow
                      key={attempt.id}
                      msg={msg}
                      attempt={attempt}
                      endpoint={endpoints.get(attempt.endpointId)!}
                    />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex alignItems="center" justifyContent="space-between" py={3} px={4}>
            <Text as="div" mr={4} variant="caption">
              Showing {pageLength} item{pageLength !== 1 ? "s" : ""}
            </Text>
            <ButtonGroup spacing={2}>
              <IconButton
                aria-label="previous page"
                disabled={iteratorStack.length === 0}
                variant="outline"
                size="sm"
                onClick={prevPage}
              >
                <ChevronLeft fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="next page"
                disabled={attempts?.done ?? true}
                variant="outline"
                size="sm"
                onClick={nextPage}
              >
                <ChevronRight fontSize="small" />
              </IconButton>
            </ButtonGroup>
          </Flex>
        </>
      )}
    </>
  );
}

function PayloadViewer({ msg, msgId }: { msg?: MessageOut; msgId: string }) {
  const user = useAppSelector((state) => state.auth.user)!;

  const { data: rawMessage, error: rawMessageError } = useAppQuery<
    MessageRawPayloadOut,
    ApiException<HttpErrorOut>
  >(["messages", "rawPayload", msgId], async () => {
    const dh = getSvix();
    const api = new MessageApi(dh._configuration);
    return api.v1MessageGetRawPayload({
      appId: user.app.id,
      msgId: msgId,
    });
  });

  const [showRaw, setShowRaw] = useBoolean();

  const rawSwitch = (
    <Box>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="raw-toggle" mb="0" mr={2}>
          Raw
        </FormLabel>
        <Switch id="raw-toggle" onChange={setShowRaw.toggle} />
      </FormControl>
    </Box>
  );

  if (msg?.payload["m"] === "EXPUNGED" && rawMessageError?.code === 404) {
    return (
      <Card
        title={
          <HStack>
            <Text>Message content</Text>
            <Tag variant="outline" size="sm" colorScheme="yellow">
              Deleted
            </Tag>
          </HStack>
        }
      >
        <Text fontSize="sm" variant="caption">
          The payload of this message has been deleted.
        </Text>
      </Card>
    );
  }

  if (msg?.payload["m"] === "NON_JSON") {
    return (
      <Card title="Message content">
        <Suspense fallback={<JsonViewerPlaceholder />}>
          <CodeBlock language="markup" code={msg.payload["raw"]} copyToClipboard />
        </Suspense>
      </Card>
    );
  }

  return (
    <Card title={showRaw ? "Raw message content" : "Message content"} cta={rawSwitch}>
      {showRaw ? (
        <Skeleton isLoaded={!!rawMessage}>
          <Suspense fallback={<JsonViewerPlaceholder />}>
            <CodeBlock language="json" code={rawMessage?.payload || ""} copyToClipboard />
          </Suspense>
        </Skeleton>
      ) : (
        <Skeleton isLoaded={!!msg}>
          <Suspense fallback={<JsonViewerPlaceholder />}>
            <JsonViewer code={msg?.payload} />
          </Suspense>
        </Skeleton>
      )}
    </Card>
  );
}
