import { EnvironmentSettingsApi } from "svix/dist/openapi";

import { AppPortalThemeProvider } from "@svix/common/widgets/ThemeProvider";

import { useAppSelector } from "src/hooks/store";
import { getSvix } from "./api";
import { useAppQuery } from "./hooks/api";

export default function ThemeProvider(
  props: React.PropsWithChildren<Record<never, never>>
) {
  const embedConfig = useAppSelector((state) => state.embedConfig);
  const darkModeUserSetting = useAppSelector((state) => state.settings.darkMode);

  const { data: orgSettings } = useAppQuery(["orgSettings"], async () => {
    const sv = getSvix();
    const config = sv._configuration;
    const api = new EnvironmentSettingsApi(config);
    return api.v1EnvironmentGetSettings({});
  });

  return (
    <AppPortalThemeProvider
      darkMode={darkModeUserSetting}
      customFontFamily={embedConfig.fontFamily}
      customFontFamilyUrl={embedConfig.fontFamilyUrl}
      baseFontSize={embedConfig.baseFontSize}
      primaryColorOverride={embedConfig.primaryColor}
      primaryLightOverride={embedConfig.primaryColorLight}
      primaryDarkOverride={embedConfig.primaryColorDark}
      paletteDark={orgSettings?.colorPaletteDark}
      paletteLight={orgSettings?.colorPaletteLight}
      themeOverrides={embedConfig.themeOverrides}
    >
      {props.children}
    </AppPortalThemeProvider>
  );
}
