import { Text } from "@chakra-ui/react";
import { Step } from "react-joyride";

import ScreenTour from "./ScreenTour";

// FIXME: These steps are not final. This is not user-facing yet.
export default function EndpointDetailsTour() {
  const dashboardTourSteps: Step[] = [
    {
      target: "#endpoint-messages-list table",
      content: (
        <>
          <Text>You can see the message we've just sent to this application here.</Text>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
    },
  ];

  const userTourSteps: Step[] = [
    {
      target: "#endpoint-messages-list table",
      content: (
        <>
          <Text>
            You can see all the messages that have been sent to this endpoint here.
          </Text>
        </>
      ),
      disableBeacon: true,
      placement: "auto",
    },
    {
      target: "#stat-signing-secret",
      content: (
        <>
          <Text>
            This is the signing secret for your endpoint. Use it to verify the
            authenticity of the messages you receive.
          </Text>
        </>
      ),
      placement: "left",
    },
    {
      target: 'button[name="testing"]',
      content: (
        <>
          <Text>You can send test messages to your endpoint from the 'Testing' tab.</Text>
        </>
      ),
    },
  ];

  return (
    <>
      <ScreenTour tourName="endpoint" steps={userTourSteps} />
      <ScreenTour tourName="endpoint" isDashboardTour steps={dashboardTourSteps} />
    </>
  );
}
