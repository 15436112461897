import { useState, useEffect } from "react";
import {
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  MenuItem,
  Tr,
  useBoolean,
} from "@chakra-ui/react";
import { Replay } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { EndpointMessageOut, EndpointOut, MessageStatus } from "svix";

import { logInfo } from "@svix/common/logger";
import { formatDateTime, getApiError } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import MessageStatusDisplay from "@svix/common/widgets/MessageStatusDisplay";
import SubmitButton from "@svix/common/widgets/SubmitButton";
import TableCell from "@svix/common/widgets/TableCell";
import TableRowMenu from "@svix/common/widgets/TableRowMenu";
import TimestampTableCell from "@svix/common/widgets/TimestampTableCell";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import { isEE } from "src/utils";
import ReplayRadio, { IReplayType } from "../Endpoint/ReplayRadio";

interface IReplayModalProps {
  msg: EndpointMessageOut;
  endpoint: EndpointOut;
  isOpen: boolean;
  onClose: () => void;
}

interface IReplayForm {
  replayType: IReplayType;
}

const defaultValues: IReplayForm = {
  replayType: "single",
};

function ReplayModal(props: IReplayModalProps) {
  const { isOpen, endpoint, msg, onClose } = props;
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.auth.user)!;
  const [error, setError] = useState("");

  async function resend(form: IReplayForm) {
    setError("");
    const api = getSvix();
    try {
      if (form.replayType === "all-failed-since") {
        await api.endpoint.recover(user.app.id, endpoint.id, {
          since: msg.timestamp,
        });
      } else if (form.replayType === "all-missing-since") {
        await api.endpoint.replayMissing(user.app.id, endpoint.id, {
          since: msg.timestamp,
        });
      } else {
        await api.messageAttempt.resend(user.app.id, msg.id, endpoint.id);
      }

      await queryClient.invalidateQueries(["endpoints", endpoint.id, "messages"]);
      onClose();
    } catch (err) {
      logInfo(err as Error, `tried to recover messages "${form.replayType}"`);
      setError(getApiError(err));
    }
  }

  const formCtx = useForm<IReplayForm>({ defaultValues });
  const { reset } = formCtx;

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Form onSubmit={resend} {...formCtx}>
        <ModalContent borderRadius="lg">
          <ModalHeader onClose={onClose}>Replay Messages</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {error && (
              <Alert status="error" mb={4} borderRadius="md">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <Stack spacing={3}>
              <ReplayRadio msg={msg} control={formCtx.control} name="replayType" />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose} mr={2}>
              Cancel
            </Button>
            <SubmitButton>Resend</SubmitButton>
          </ModalFooter>
        </ModalContent>
      </Form>
    </Modal>
  );
}

interface IEndpointTableRowProps {
  msg: EndpointMessageOut;
  endpoint: EndpointOut;
  enableChannels: boolean;
}

export default function EndpointTableRow(props: IEndpointTableRowProps) {
  const [showReplayModal, setShowReplayModal] = useBoolean();
  const { msg, endpoint } = props;

  let nextAttempt;
  if (msg.nextAttempt) {
    nextAttempt =
      msg.status === MessageStatus.Sending
        ? `Attempt started at ${formatDateTime(msg.nextAttempt, { withMs: true })}`
        : `Next attempt at ${formatDateTime(msg.nextAttempt, { withMs: true })}`;
  } else {
    if (msg.status === MessageStatus.Pending) {
      // Should only happen with old events that don't have nextAttempt yet
      nextAttempt = "Next attempt is pending";
    } else {
      nextAttempt = "";
    }
  }

  const to = routeResolver.getRoute("messages._id", { msgId: msg.id });

  return (
    <>
      <Tr>
        <TableCell isCondensed to={to} scope="row" align="center" w="8em">
          <MessageStatusDisplay status={msg.status} tooltip={nextAttempt} />
        </TableCell>
        <TableCell isCondensed to={to} mono>
          {msg.eventType}
        </TableCell>
        {props.enableChannels && (
          <TableCell isCondensed to={to} mono>
            {msg.channels?.join(", ")}
          </TableCell>
        )}
        <TableCell to={to} mono isTruncated isCondensed minW="9em" maxW={0} width="40%">
          {msg.eventId ?? msg.id}
        </TableCell>
        <TimestampTableCell isCondensed to={to} ts={msg.timestamp} />
        {!isEE && (
          <TableRowMenu>
            <MenuItem onClick={setShowReplayModal.on} icon={<Replay fontSize="small" />}>
              Replay...
            </MenuItem>
            <ReplayModal
              isOpen={showReplayModal}
              onClose={setShowReplayModal.off}
              endpoint={endpoint}
              msg={msg}
            />
          </TableRowMenu>
        )}
      </Tr>
    </>
  );
}
