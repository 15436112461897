import { useEffect } from "react";
import { ButtonGroup } from "@chakra-ui/react";
import { MessageStatus } from "svix";

import { setSearch } from "@svix/common/hooks/search";
import Button from "@svix/common/widgets/Button";

export default function MessageStatusFilter(props: {
  value: MessageStatus | undefined;
  onChange: (value: MessageStatus | undefined) => void;
}) {
  const { onChange, value } = props;
  const filterOptions = [
    {
      label: "All",
      status: undefined,
    },
    {
      label: "Succeeded",
      status: MessageStatus.Success,
    },
    {
      label: "Failed",
      status: MessageStatus.Fail,
    },
  ];

  // Persist value to search params
  useEffect(() => {
    setSearch({ status: value });
  }, [value]);

  return (
    <ButtonGroup isAttached size="sm">
      {filterOptions.map((x) => (
        <Button
          key={x.label}
          colorScheme="gray"
          variant={value === x.status ? "filterGroupActive" : "filterGroupInactive"}
          onClick={() => onChange(x.status)}
        >
          {x.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
