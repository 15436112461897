import * as React from "react";
import {
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  List,
  Text,
} from "@chakra-ui/react";
import { ContactSupport, EmojiObjects, Help, LocalLibrary } from "@material-ui/icons";

import ListItemLink from "./ListItemLink";
import * as C from "../constants";

interface IListItemTextProps {
  primary: React.ReactNode;
  secondary: React.ReactNode;
}
function ListItemText(props: IListItemTextProps) {
  return (
    <Box ml={3}>
      <Text>{props.primary}</Text>
      <Text variant="caption">{props.secondary}</Text>
    </Box>
  );
}

export default function HelpBarItem() {
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton variant="appbar" aria-label="open help" data-cy="help-menu">
          <Help />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent mx={2}>
        <PopoverArrow />
        <PopoverBody p={2}>
          <List>
            <ListItemLink to={C.docs.index} display="flex" alignItems="center">
              <Box color="white" bg="gray.400" borderRadius="full" p="2">
                <LocalLibrary />
              </Box>
              <ListItemText
                primary="Looking for some light reading?"
                secondary="Check our documentation"
              />
            </ListItemLink>
            <ListItemLink to={C.contactUrl} display="flex" alignItems="center">
              <Box color="white" bg="gray.400" borderRadius="full" p="2">
                <ContactSupport />
              </Box>
              <ListItemText
                primary="Need support?"
                secondary="Contact us using this link."
              />
            </ListItemLink>
            <ListItemLink to={C.slackUrl} display="flex" alignItems="center">
              <Box color="white" bg="gray.400" borderRadius="full" p="2">
                <EmojiObjects />
              </Box>
              <ListItemText
                primary="Have an idea or feedback for us?"
                secondary="Join our Slack community"
              />
            </ListItemLink>
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
