import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ITourContext {
  userTourActive: boolean;
  dashboardTourActive: boolean;
  tours: {
    [key in ITourName]: ITour;
  };
}

interface ITour {
  tourStep: number;
  run: boolean;
  done: boolean;
}

const TOUR_NAMES = <const>["endpoints", "endpoint"];
export type ITourName = typeof TOUR_NAMES[number];

const initialState: ITourContext = {
  userTourActive: false,
  dashboardTourActive: false,
  tours: {
    endpoints: {
      tourStep: 0,
      run: false,
      done: false,
    },
    endpoint: {
      tourStep: 0,
      run: false,
      done: false,
    },
  },
};

const slice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setUserTourActive(state: ITourContext) {
      state.userTourActive = true;
      state.dashboardTourActive = false;
    },
    setDashboardTourActive(state: ITourContext) {
      state.dashboardTourActive = true;
      state.userTourActive = false;
    },
    setTourInactive(state: ITourContext) {
      state.userTourActive = false;
      state.dashboardTourActive = false;
    },
    startTour(state: ITourContext, action: PayloadAction<ITourName>) {
      state.tours[action.payload].tourStep = 0;
      state.tours[action.payload].run = true;
    },
    resumeTour(state: ITourContext, action: PayloadAction<ITourName>) {
      state.tours[action.payload].run = true;
    },
    pauseTour(state: ITourContext, action: PayloadAction<ITourName>) {
      state.tours[action.payload].run = false;
    },
    finishTour(state: ITourContext, action: PayloadAction<ITourName>) {
      state.tours[action.payload].run = false;
      state.tours[action.payload].done = true;
    },
    resetAllTours(state: ITourContext) {
      state.tours = initialState.tours;
    },
    setTourStep(
      state: ITourContext,
      action: PayloadAction<{ tourName: ITourName; tourStep: number }>
    ) {
      state.tours[action.payload.tourName].tourStep = action.payload.tourStep;
    },
  },
});

export const {
  startTour,
  finishTour,
  setTourStep,
  pauseTour,
  resumeTour,
  setUserTourActive,
  setDashboardTourActive,
  setTourInactive,
  resetAllTours,
} = slice.actions;
export default slice.reducer;
