import { forwardRef } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Td,
  TableCellProps,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";

const TableRowMenu = forwardRef((props: TableCellProps, ref: any) => {
  const { children } = props;

  return (
    <Td isNumeric ref={ref}>
      <Menu placement="bottom-end">
        <MenuButton as={IconButton} variant="rounded" aria-label="more-options">
          <MoreVert />
        </MenuButton>
        <MenuList>{children}</MenuList>
      </Menu>
    </Td>
  );
});

export default TableRowMenu;
