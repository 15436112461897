import { Stack, Text, Link, ListItem, OrderedList, Image } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { EndpointIn } from "svix";

import TextField from "@svix/common/widgets/form/TextField";

import zapierGuideImg from "src/img/integrations/zapier-guide.png";
const zapierDocsURL = "https://zapier.com/apps/webhook/integrations";

export default function ZapierIntegration(props: {
  formCtx: UseFormReturn<EndpointIn, any>;
}) {
  const { formCtx } = props;

  return (
    <Stack spacing={4}>
      <Text fontWeight="semibold">To connect to Zapier, follow these steps:</Text>
      <OrderedList spacing={1} stylePosition="inside">
        <ListItem>
          Create a new Zap and select Webhooks by Zapier as the trigger.
        </ListItem>
        <ListItem>Select Catch Hook as the event and hit Continue.</ListItem>
        <Image py={2} src={zapierGuideImg} maxW="24em" />
        <ListItem>
          In the Test tab, copy the Zapier webhook URL. Paste the URL below.
        </ListItem>
      </OrderedList>
      <Link isExternal href={zapierDocsURL} color="interactive.accent">
        Learn more about using Zapier with webhooks
      </Link>
      <TextField
        autoFocus
        control={formCtx.control}
        name="url"
        label="Zapier Webhook URL"
        type="url"
        isRequired
        placeholder="e.g. https://hooks.zapier.com/hooks/catch/15685890/3fhzhhk/"
      />
    </Stack>
  );
}
