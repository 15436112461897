import { useCallback, useEffect, useState } from "react";
import {
  Image,
  ButtonProps,
  Collapse,
  HStack,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { EndpointIn } from "svix";
import { TransformationTemplateApi } from "svix/dist/openapi";

import * as C from "@svix/common/constants";
import { logError } from "@svix/common/logger";
import Button from "@svix/common/widgets/Button";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import discordLogo from "src/img/integrations/discord-icon.svg";
import { getPopupWindowFeatures } from "../../../hooks/integrations/utils";

export type DiscordIntegrationProps = {
  formCtx: UseFormReturn<EndpointIn, any>;
};

// FIXME: Figure out a way to reuse some code between this component and the one
// for Slack. Maybe we can use a custom oauth hook.
export default function DiscordIntegration(props: DiscordIntegrationProps) {
  const { formCtx } = props;
  const [showEndpointURL, setShowEndpointURL] = useState(false);
  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [connectedToDiscord, setConnectedToDiscord] = useState(false);
  const [oauthError, setOauthError] = useState<string | null>(null);

  const redirectUri = `https://redirectmeto.com/${
    window.location.origin
  }${routeResolver.getRoute("oauth.discord.authorize")}`;

  const fetchToken = useCallback(
    async (code: string) => {
      try {
        const svix = getSvix();
        const api = new TransformationTemplateApi(svix._configuration);
        const res = await api.v1TransformationTemplateOauthDiscord({
          oAuthPayloadIn: {
            code,
            redirectUri,
          },
        });

        if (!res.incomingWebhookUrl || res.error) {
          logError("Failed to connect to Discord", res.error);
          setOauthError("Failed to connect to Discord. Please try again.");
          return;
        }

        formCtx.setValue("url", res.incomingWebhookUrl || "");
        setShowEndpointURL(true);
        setConnectedToDiscord(true);
      } catch (err) {
        logError(err);
        setOauthError("Failed to connect to Discord. Please try again.");
      }
    },
    [formCtx, redirectUri, setOauthError, setShowEndpointURL, setConnectedToDiscord]
  );

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }

        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        const authorizeError = searchParams.get("error");

        if (code || authorizeError) {
          if (code) {
            fetchToken(code);
          }

          if (authorizeError) {
            setOauthError("Failed to connect to Discord. Please try again.");
          }
          externalPopup.close();
          setExternalPopup(null);
          clearInterval(timer);
        }
      } catch (e) {
        // Ignore error. Keep polling until user is redirected.
      }
    }, 100);
  }, [externalPopup, fetchToken]);

  const onConnectToDiscord = useCallback(() => {
    const popup = window.open(
      C.getDiscordAppPortalOauthAuthorizeUrl(redirectUri),
      "_blank",
      getPopupWindowFeatures()
    );
    setExternalPopup(popup);
  }, [redirectUri]);

  return (
    <Stack>
      <Text fontWeight="semibold">Endpoint URL</Text>
      <HStack>
        <DiscordButton onClick={onConnectToDiscord} isDisabled={connectedToDiscord}>
          {connectedToDiscord ? "Connected" : "Connect to Discord"}
        </DiscordButton>
        <Text>
          {"or "}
          <StyledLink
            onClick={() => {
              setShowEndpointURL(true);
              setConnectedToDiscord(false);
            }}
          >
            <Tooltip
              label="Use an Incoming Webhooks URL from you own Discord app, if you have created one"
              fontSize="md"
            >
              Add your own Discord URL
            </Tooltip>
          </StyledLink>
        </Text>
      </HStack>
      <Collapse in={showEndpointURL}>
        <TextField
          control={formCtx.control}
          name="url"
          helperText="The URL where the webhook events will be sent to."
          type="url"
          isRequired
          isDisabled={connectedToDiscord}
          placeholder="e.g. https://www.example.com/webhook"
        />
      </Collapse>
      {oauthError && <Text color="red.500">{oauthError}</Text>}
    </Stack>
  );
}

const DiscordButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      leftIcon={<Image height="1.4em" src={discordLogo} />}
      {...rest}
      variant="outline"
    >
      {children}
    </Button>
  );
};
