import * as React from "react";
import {
  Box,
  Table as BaseTable,
  TableProps,
  TableCaption,
  Link,
} from "@chakra-ui/react";

import { FastApiResponse, IRequestElems } from "hooks/pagination";

import Pagination, { PaginationWithPrevIter } from "./Pagination";

interface ITableProps extends TableProps {
  emptyState?: React.ReactNode | string;
  response: FastApiResponse | undefined;
  requestElems: IRequestElems;
  horizScroll?: boolean;
  hasPrevIter?: boolean;
  showPagination?: boolean;
}

function checkForData(response: FastApiResponse | undefined): boolean | undefined {
  if (!response) {
    return undefined;
  }
  if (Array.isArray(response.data)) {
    return response.data.length > 0;
  }
  return !!response.data;
}

export function TableContainer(props: TableProps) {
  return (
    <Box
      overflow="hidden"
      bgColor="background.secondary"
      border="1px solid"
      borderRadius="table"
      borderColor="background.modifier.border"
      {...props}
    />
  );
}

export default function Table(props: ITableProps) {
  const {
    children,
    emptyState,
    hasPrevIter,
    horizScroll,
    response,
    requestElems,
    showPagination = true,
    ...rest
  } = props;
  const hasData = checkForData(response);
  const isFlippingPage = requestElems.isLoadingNext || requestElems.isLoadingPrev;
  const PaginationComponent = hasPrevIter ? PaginationWithPrevIter : Pagination;

  return (
    <>
      <TableContainer>
        <BaseTable
          size="sm"
          transition="opacity 0.2s ease-in-out"
          opacity={isFlippingPage ? 0.5 : undefined}
          {...rest}
        >
          {children}

          {hasData === false && (
            <TableCaption my={3}>
              {requestElems.error && (
                <>
                  Failed to retrieve data.
                  <Link ml="0.2em" to="#" onClick={requestElems.refetch} color="blue.500">
                    Retry
                  </Link>
                </>
              )}
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {!requestElems.error && (emptyState || "No data available")}
            </TableCaption>
          )}
        </BaseTable>
      </TableContainer>
      {!requestElems.isLoading && showPagination && (
        <Box py={3} px={4}>
          <PaginationComponent response={response} {...requestElems} />
        </Box>
      )}
    </>
  );
}
