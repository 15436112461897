import { EndpointIn, EndpointOut } from "svix";

export type SinkIn = EndpointIn & SinkType;

export type HttpSink = {
  type: "http";
  url: string;
};

export type RabbitMQSink = {
  type: "rabbitMQ";
  uri: string;
  routingKey: string;
};

export type SQSSink = {
  type: "sqs";
  region: string;
  queueDsn: string;
  accessKey: string;
  secretKey: string;
};

export type SinkType = RabbitMQSink | SQSSink | HttpSink;

export type SinkTypeName = "rabbitMQ" | "sqs" | "http";

export type SinkOut = Omit<EndpointOut, "url"> & SinkType;

export const SinksApi = (token: string, serverUrl: string) => {
  const fetchOptions = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const throwingFetch = async (url: string, options: RequestInit) => {
    const res = await fetch(url, options);
    const resJson = await res.json();
    if (!res.ok) {
      throw resJson;
    }
    return resJson;
  };

  return {
    async createSink(appId: string, sinkIn: SinkIn) {
      return throwingFetch(`${serverUrl}/api/v1/app/${appId}/sink`, {
        ...fetchOptions,
        method: "POST",
        body: JSON.stringify(sinkIn),
      });
    },
    async listSinks(appId: string) {
      return throwingFetch(`${serverUrl}/api/v1/app/${appId}/sink`, {
        ...fetchOptions,
        method: "GET",
      });
    },
    async getSink(appId: string, sinkId: string) {
      return throwingFetch(`${serverUrl}/api/v1/app/${appId}/sink/${sinkId}`, {
        ...fetchOptions,
        method: "GET",
      });
    },
  };
};
